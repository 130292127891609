import { useState } from "react"
import styled from "styled-components"

import Button, { SubmitButton } from "~/components/Button"
import InputField from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalInputsRow, ModalText, ModalTitle } from "~/components/Modal"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import trpc from "~/utilities/trpc"

const CustomEmailModal: React.FC<{ isOpen: boolean; setOpen: (value: boolean) => void }> = ({ isOpen, setOpen }) => {
	const currentUser = useUser()
	const alert = useAlert()

	const [isSubmitting, setSubmitting] = useState(false)
	const [host, setHost] = useState("")
	const [port, setPort] = useState("465")
	const [email, setEmail] = useState("")
	const [user, setUser] = useState("")
	const [password, setPassword] = useState("")

	const isSubmitDisabled = [host, port, email, user, password].some(x => x.trim().length === 0)

	const handleSubmit = async () => {
		if (isSubmitDisabled) return

		setSubmitting(true)
		try {
			const response = await trpc.platform.setEmailLogin.mutate({
				host,
				port: parseInt(port),
				email,
				user,
				password,
			})
			if (!response.isSuccess) {
				setSubmitting(false)
				await alert.show("Connection failed", "Failed to connect to SMTP server. Please check your details.")
				return
			}
			setOpen(false)
			await currentUser.reload()
		} catch {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSubmit}>
			<ModalTitle>Connect custom email sender</ModalTitle>
			<ModalText>
				If you want to send email to your users from a custom email address, you can connect it here using SMTP.
			</ModalText>
			<ModalInputs>
				<ModalInputsRow>
					<InputField label="Host" value={host} onChange={setHost} />
					<PortInputField value={port} onChange={setPort} />
				</ModalInputsRow>
				<InputField label="Email address" inputType="email" value={email} onChange={setEmail} />
				<InputField label="User" value={user} onChange={setUser} />
				<InputField label="Password" inputType="password" value={password} onChange={setPassword} />
			</ModalInputs>
			<ModalButtons>
				<Button variant="secondary" onClick={() => setOpen(false)}>
					Cancel
				</Button>
				<SubmitButton variant="primary" isDisabled={isSubmitDisabled} isLoading={isSubmitting}>
					Save
				</SubmitButton>
			</ModalButtons>
		</DefaultModal>
	)
}

const PortInputField = styled(InputField).attrs({ inputType: "number", label: "Port" })`
	width: 200px;
`

export default CustomEmailModal
