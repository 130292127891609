import { type FC } from "react"

import { type AddonType, type AddonDetails } from "@forento/shared/models/payment"
import { type AppSumoPlatformPlan, type VieDeDinguePlatformPlan } from "@forento/shared/models/platform"

import Button, { type ButtonVariant } from "~/components/Button"
import { useUser } from "~/contexts/UserContext"
import trpc from "~/utilities/trpc"

import Plan, {
	PlanTitle,
	PlanPrice,
	PlanInterval,
	PlanBillingInterval,
	PlanDescription,
	PlanDivider,
	PlanUsps,
	PlanUspContainer,
	PlanUspIcon,
	PlanUspText,
} from "../Plan"

type Props = {
	type: AddonType
	details: AddonDetails
	platformPlan: AppSumoPlatformPlan | VieDeDinguePlatformPlan
	isMonthlySelected: boolean
	isSubmitting: boolean
	isDisabled: boolean
	setSubmitting(value: boolean): void
	onCancel(): void
}
const Addon: FC<Props> = ({
	type,
	details,
	platformPlan,
	isMonthlySelected,
	isSubmitting,
	isDisabled,
	setSubmitting,
	onCancel,
}) => {
	const user = useUser()

	const callToAction: { variant: ButtonVariant; label: string; action: "start" | "cancel" } = (() => {
		if (platformPlan.addon) {
			if (platformPlan.addon.type !== type) {
				return { variant: "secondary", label: "Switch to this plan", action: "start" }
			}
			if (platformPlan.addon.endDate) {
				return {
					variant: "primary",
					label: "Resume plan",
					action: "start",
				}
			} else {
				if ((platformPlan.addon.paymentInterval === "month") === isMonthlySelected) {
					return {
						variant: "secondary-danger",
						label: "Cancel plan",
						action: "cancel",
					}
				} else {
					return {
						variant: "secondary",
						label: `Switch to ${isMonthlySelected ? "monthly" : "annual"} payment`,
						action: "start",
					}
				}
			}
		}
		return {
			variant: "primary",
			label: "Start plan",
			action: "start",
		}
	})()

	async function handleSubmit() {
		if (callToAction.action === "cancel") {
			onCancel()
			return
		}
		setSubmitting(true)
		try {
			const { redirectUrl } = await trpc.payment.creator.addAddon.mutate({
				type,
				paymentInterval: isMonthlySelected ? "month" : "year",
				source: "settings",
			})
			if (redirectUrl) {
				window.location.href = redirectUrl
			}
			await user.reload()
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Plan>
			<PlanTitle>{details.title}</PlanTitle>
			<PlanPrice>
				${isMonthlySelected ? details.prices.month.amount : details.prices.year.amount / 12}
				<PlanInterval>/month</PlanInterval>
			</PlanPrice>
			<PlanBillingInterval>Billed {isMonthlySelected ? "monthly" : "annually"}</PlanBillingInterval>
			<PlanDescription>{details.description}</PlanDescription>
			<PlanDivider />
			<PlanUsps>
				{details.usps.map((usp, index) => (
					<PlanUspContainer key={index}>
						<PlanUspIcon />
						<PlanUspText>{usp}</PlanUspText>
					</PlanUspContainer>
				))}
			</PlanUsps>
			<PlanDivider />
			<Button
				variant={callToAction.variant}
				onClick={handleSubmit}
				isLoading={isSubmitting}
				isDisabled={isDisabled}
			>
				{callToAction.label}
			</Button>
		</Plan>
	)
}

export default Addon
