import { type FC } from "react"
import styled, { css } from "styled-components"

import { getAlphabetString } from "@forento/shared/utilities/string"

import Button from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import { DeleteIcon } from "~/components/Icon"
import { useAlert } from "~/contexts/AlertContext"
import { useToast } from "~/contexts/ToastContext"
import { dangerColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import InlineTextEditor from "./InlineTextEditor"

interface Props {
	answer: {
		id: number
		text: string
		isCorrect: boolean
	}
	index: number
	onChange(): void
}

const Answer: FC<Props> = ({ answer, index, onChange }) => {
	const alert = useAlert()
	const toast = useToast()

	const handleCorrectChange = async (value: boolean) => {
		try {
			await trpc.exam.updateAnswer.mutate({ id: answer.id, data: { isCorrect: value } })
			onChange()
			toast.showChangesSaved("Question")
		} catch (error) {
			console.error(error)
		}
	}

	const handleSaveText = async (text: string) => {
		try {
			await trpc.exam.updateAnswer.mutate({ id: answer.id, data: { text } })
			onChange()
			toast.showChangesSaved("Question")
		} catch (error) {
			console.error(error)
		}
	}

	const handleDelete = async () => {
		const dialog = await alert.confirm(
			"Delete answer",
			"Are you sure you want to delete this answer? This action cannot be undone.",
		)
		if (!dialog.result) return

		try {
			await trpc.exam.deleteAnswer.mutate(answer.id)
			dialog.close()
			onChange()
			toast.showDeleted("Answer")
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<Container $isCorrect={answer.isCorrect}>
			<Checkbox isChecked={answer.isCorrect} onChange={handleCorrectChange} />
			{getAlphabetString(index)}. <InlineTextEditor text={answer.text} onSubmit={handleSaveText} />
			<DeleteButton onClick={handleDelete}>
				<DeleteIcon />
			</DeleteButton>
		</Container>
	)
}

const Container = styled.div<{ $isCorrect: boolean }>`
	display: flex;
	align-items: center;
	gap: 8px;

	${props =>
		props.$isCorrect &&
		css`
			font-weight: bold;
		`}
`

const DeleteButton = styled(Button)`
	width: 24px;
	height: 24px;
	color: ${dangerColor};
`

export default Answer
