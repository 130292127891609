import { type ComponentType, type ReactNode } from "react"

import { type PlanAccess } from "@forento/shared/models/payment"

import Layout from "~/components/Layout"
import LockedFeature from "~/components/LockedFeature"
import { useUser } from "~/contexts/UserContext"

const withAccessRequirement = <T extends JSX.IntrinsicAttributes>(
	access: PlanAccess,
	header: ReactNode,
	WrappedComponent: ComponentType<T>,
): React.FC<T> => {
	return props => {
		const platform = useUser().user!.platform!

		if (!platform.plan.access[access])
			return (
				<Layout>
					{header}
					<LockedFeature />
				</Layout>
			)

		return <WrappedComponent {...props} />
	}
}

export default withAccessRequirement
