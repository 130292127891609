import { type FC, type ReactNode, useCallback, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useBeforeUnload, useBlocker } from "react-router"
import styled, { css, keyframes } from "styled-components"
import randomUUID from "uuid-random"

import Button from "~/components/Button"
import { successColor } from "~/utilities/styles"

import { ToastContext, type ToastContextValue } from "."

const ToastContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [toasts, setToasts] = useState<{ id: string; text: string; isExiting?: boolean }[]>([])
	const [handleSave, setHandleSave] = useState<() => Promise<boolean>>()
	const [isClosingSave, setClosingSave] = useState(false)
	const [isSaving, setSaving] = useState(false)
	const hasEverShowedSave = useRef(false)

	useBlocker(() => {
		if (!handleSave) return false

		const result = confirm(
			"You have unsaved changes. Navigating away from the page will discard the changes. Are you sure?",
		)
		return !result
	})

	const handleUnload = useCallback(
		(event: BeforeUnloadEvent) => {
			if (!handleSave) return

			event.preventDefault()
			const message =
				"You have unsaved changes. Navigating away from the page will discard the changes. Are you sure?"
			event.returnValue = message
			return message
		},
		[handleSave],
	)
	useBeforeUnload(handleUnload, { capture: true })

	const handleShowChangesSaved = useCallback((changeName?: string) => {
		const id = randomUUID()
		setToasts(current => [
			...current,
			{ id, text: `${changeName ? `${changeName} was` : "Changes were"} saved successfully` },
		])

		setTimeout(() => {
			setToasts(current => current.map(toast => (toast.id === id ? { ...toast, isExiting: true } : toast)))

			setTimeout(() => {
				setToasts(current => current.filter(toast => toast.id !== id))
			}, 500)
		}, 3000)
	}, [])

	const handleShowPublished = useCallback((productName: string) => {
		const id = randomUUID()
		setToasts(current => [...current, { id, text: `${productName} was published successfully` }])

		setTimeout(() => {
			setToasts(current => current.map(toast => (toast.id === id ? { ...toast, isExiting: true } : toast)))

			setTimeout(() => {
				setToasts(current => current.filter(toast => toast.id !== id))
			}, 500)
		}, 3000)
	}, [])

	const handleShowDeleted = useCallback((productName: string) => {
		const id = randomUUID()
		setToasts(current => [...current, { id, text: `${productName} was deleted successfully` }])

		setTimeout(() => {
			setToasts(current => current.map(toast => (toast.id === id ? { ...toast, isExiting: true } : toast)))

			setTimeout(() => {
				setToasts(current => current.filter(toast => toast.id !== id))
			}, 500)
		}, 3000)
	}, [])

	const value = useMemo<ToastContextValue>(
		() => ({
			showChangesSaved: handleShowChangesSaved,
			showPublished: handleShowPublished,
			showDeleted: handleShowDeleted,
			setUnsavedChanges(onSave) {
				setHandleSave(() => onSave)
			},
			clearUnsavedChanges() {
				setHandleSave(undefined)
			},
		}),
		[handleShowChangesSaved, handleShowDeleted, handleShowPublished],
	)

	useLayoutEffect(() => {
		if (handleSave) {
			hasEverShowedSave.current = true
		}
		if (!handleSave && hasEverShowedSave.current) {
			setClosingSave(true)
			setTimeout(() => {
				setClosingSave(false)
			}, 500)
		}
	}, [handleSave, handleShowChangesSaved])

	return (
		<ToastContext.Provider value={value}>
			<Container>
				{toasts.map(toast => (
					<Toast key={toast.id} $type="success" $isExiting={toast.isExiting ?? false}>
						{toast.text}
					</Toast>
				))}
				{(handleSave || isClosingSave) && (
					<Toast key="unsaved" $type="unsaved" $isExiting={isClosingSave}>
						You have unsaved changes
						<Button
							variant="primary"
							isLoading={isSaving}
							onClick={async () => {
								if (!handleSave) return
								setSaving(true)
								const result = await handleSave()
								if (!result) {
									setSaving(false)
									return
								}

								setHandleSave(undefined)
								setTimeout(() => {
									handleShowChangesSaved()
									setSaving(false)
								}, 500)
							}}
						>
							Save changes
						</Button>
					</Toast>
				)}
			</Container>
			{children}
		</ToastContext.Provider>
	)
}

const Container = styled.div`
	position: fixed;
	bottom: 42px;
	left: 50%;
	transform: translateX(-50%);
	width: 600px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	z-index: 1000;
`

const enterAnimation = keyframes`
	from {
		opacity: 0;
		transform: translateY(100%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
`

const exitAnimation = keyframes`
	from {
		opacity: 1;
		transform: translateY(0);
	}
	to {
		opacity: 0;
		transform: translateY(100%);
	}
`

const Toast = styled.div<{ $isExiting: boolean; $type: "success" | "unsaved" }>`
	font-size: 18px;
	box-shadow:
		0 4px 8px rgba(0, 0, 0, 0.1),
		0 2px 4px rgba(0, 0, 0, 0.1);
	padding: 12px 24px;
	border-radius: 8px;
	opacity: 0;
	transform: translateY(100%);
	animation: ${props => (props.$isExiting ? exitAnimation : enterAnimation)} 0.5s forwards;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${props =>
		props.$type === "success"
			? css`
					color: white;
					background-color: ${successColor};
				`
			: props.$type === "unsaved"
				? css`
						color: black;
						background-color: #ffa07a;
					`
				: null}
`

export default ToastContextProvider
