import { type FC } from "react"
import styled from "styled-components"

import ButtonBase from "~/components/Button"
import { useUser } from "~/contexts/UserContext"
import { primaryColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

const AdminPanel: FC = () => {
	const user = useUser()

	async function handleClearPlan() {
		await trpc.admin.clearPlan.mutate()
		await user.reload()
	}

	async function handleSetAppSumo() {
		await trpc.admin.setAppSumo.mutate()
		await user.reload()
	}

	return (
		<Container>
			<Button onClick={handleClearPlan}>-</Button>
			<Button onClick={handleSetAppSumo}>AS</Button>
		</Container>
	)
}

const Container = styled.div`
	position: fixed;
	top: 16px;
	right: 16px;
	background-color: #f2f2f2;
	display: flex;
	gap: 8px;
`

const Button = styled(ButtonBase)`
	font-size: 18px;
	color: white;
	background-color: ${primaryColor};
	padding: 0 4px;
`

export default AdminPanel
