import { useEffect, useState } from "react"
import { useSearchParams } from "react-router"
import styled from "styled-components"

import Button from "~/components/Button"
import InputField from "~/components/InputField"
import Layout, { PageBreadcrumb, PageHeader } from "~/components/Layout"
import { DefaultModal, ModalButtons, ModalInputs, ModalText, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import withAccessRequirement from "~/hocs/withAccessRequirement"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

import AutomaticDomain from "./AutomaticDomain"
import ManualDomain from "./ManualDomain"

export type RadioButtonId = "automatic" | "manual"

const title = <PageBreadcrumb title="Custom Domain" path={[{ title: "Settings", link: routes.settings.index() }]} />

const DomainSettingsPage: React.FC = () => {
	const user = useUser()
	const [searchParams] = useSearchParams()
	const alert = useAlert()

	const { data: domains, error, mutate } = swr.domain.list.useSWR()

	const [addDomainModal, setAddDomainModal] = useState<{
		isOpen: boolean
		state: "choose" | "manual"
		input: string
		isSubmittingManualDomain: boolean
	}>({ isOpen: false, state: "choose", input: "", isSubmittingManualDomain: false })

	const completedPurchaseId = (() => {
		const param = searchParams.get("purchase-completed")
		if (param === null) return null
		const number = parseInt(param)
		if (isNaN(number)) return null
		return number
	})()

	const isWaitingForPurchasedDomain =
		domains !== undefined && completedPurchaseId !== null && !domains.some(x => x.id === completedPurchaseId)

	useEffect(() => {
		if (!isWaitingForPurchasedDomain) return

		const timer = setTimeout(mutate, 2000)

		return () => {
			clearTimeout(timer)
		}
	}, [isWaitingForPurchasedDomain, mutate])

	const handleAddNewDomain = async () => {
		if (addDomainModal.input.trim().length === 0) return

		setAddDomainModal(current => ({ ...current, isSubmittingManualDomain: true }))
		try {
			const { status } = await trpc.domain.createManual.mutate({ domain: addDomainModal.input })
			if (status === "invalid-domain") {
				await alert.show(
					"Invalid domain",
					"The domain you entered is invalid. Make sure you only include the domain without any slashes.",
				)
				return
			}
			await mutate()
			setAddDomainModal({ isOpen: false, state: "choose", input: "", isSubmittingManualDomain: false })
		} catch (error) {
			console.error(error)
		} finally {
			setAddDomainModal(current => ({ ...current, isSubmittingManualDomain: false }))
		}
	}

	return (
		<Layout>
			<DefaultModal isOpen={addDomainModal.isOpen}>
				<ModalTitle>Add a new domain</ModalTitle>
				{addDomainModal.state === "choose" ? (
					<>
						<ModalText>
							Do you want to register a domain with us or do you already have a domain you want to
							configure manually?
						</ModalText>
						<ModalButtons $vertical>
							<Button variant="primary" onClick={routes.settings.domain.register()}>
								I want to register a new domain
							</Button>
							<Button
								variant="secondary"
								onClick={() => setAddDomainModal(current => ({ ...current, state: "manual" }))}
							>
								I already have a domain
							</Button>
							<Button
								variant="secondary"
								onClick={() =>
									setAddDomainModal({
										isOpen: false,
										input: "",
										state: "choose",
										isSubmittingManualDomain: false,
									})
								}
							>
								Cancel
							</Button>
						</ModalButtons>
					</>
				) : addDomainModal.state === "manual" ? (
					<>
						<ModalText>
							When adding an existing domain, you will need to manually configure your DNS settings.
						</ModalText>
						<ModalInputs>
							<InputField
								label="Domain"
								placeholder="mydomain.com"
								value={addDomainModal.input}
								onChange={value => setAddDomainModal(current => ({ ...current, input: value }))}
							/>
						</ModalInputs>
						<ModalButtons>
							<Button
								variant="secondary"
								onClick={() => setAddDomainModal(current => ({ ...current, state: "choose" }))}
								isDisabled={addDomainModal.isSubmittingManualDomain}
							>
								Back
							</Button>
							<Button
								variant="primary"
								onClick={handleAddNewDomain}
								isLoading={addDomainModal.isSubmittingManualDomain}
							>
								Add domain
							</Button>
						</ModalButtons>
					</>
				) : null}
			</DefaultModal>
			<PageHeader>
				{title}
				<Button
					variant="primary"
					onClick={() => {
						setAddDomainModal({ isOpen: true, state: "choose", input: "", isSubmittingManualDomain: false })
					}}
				>
					Add new domain
				</Button>
			</PageHeader>
			{isWaitingForPurchasedDomain ? (
				<LoadingContainer>
					<PartialLoadingPage />
					<p>Completing domain purchase...</p>
				</LoadingContainer>
			) : error ? (
				<p>Failed to load domains.</p>
			) : domains === undefined ? (
				<PartialLoadingPage />
			) : (
				<>
					<Headers>
						<HeaderCell style={{ flex: "2 0 0" }} />
						<HeaderCell style={{ flex: "1 0 0", display: "flex", justifyContent: "center" }}>
							Expires
						</HeaderCell>
						<HeaderCell style={{ flex: "1 0 0", display: "flex", justifyContent: "center" }}>
							Auto renew
						</HeaderCell>
						<HeaderCell style={{ flex: "0 0 320px", display: "flex", justifyContent: "flex-end" }} />
					</Headers>
					<Domains>
						{domains.map(domain =>
							domain.type === "automatic" ? (
								<AutomaticDomain
									key={`auto-${domain.id}`}
									domain={domain}
									reload={async () => {
										await user.reload()
										await mutate()
									}}
								/>
							) : (
								<ManualDomain
									key={`manual-${domain.id}`}
									domain={domain}
									reload={async () => {
										await user.reload()
										await mutate()
									}}
								/>
							),
						)}
					</Domains>
				</>
			)}
		</Layout>
	)
}

const Headers = styled.div`
	display: flex;
	margin: 0 22px 12px;
`

const HeaderCell = styled.div`
	font-weight: 600;
	font-size: 14px;
`

const Domains = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const LoadingContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export default withAccessRequirement("customDomain", title, DomainSettingsPage)
