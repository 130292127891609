import { useEffect, useState } from "react"
import styled from "styled-components"

import GoogleSigninButton from "~/components/GoogleSigninButton"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import { useAlert } from "~/contexts/AlertContext"
import { useToast } from "~/contexts/ToastContext"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

import ChangePassword from "./ChangePassword"
import ProfileAvatar from "./ProfileAvatar"
import SetPassword from "./SetPassword"
import { SectionTitle, Section, StyledInputField } from "./styles"

const AccountSettingsPage: React.FC = () => {
	const user = useUser()
	const alert = useAlert()
	const toast = useToast()

	const [firstName, setFirstName] = useState(user.user!.firstName)
	const [lastName, setLastName] = useState(user.user!.lastName)

	useEffect(() => {
		const isModified = firstName !== user.user!.firstName || lastName !== user.user!.lastName

		if (isModified) {
			toast.setUnsavedChanges(async () => {
				const data = {
					firstName: firstName.trim(),
					lastName: lastName.trim(),
				}
				if (Object.values(data).some(x => x.length === 0)) {
					alert.show("Error", "Your name cannot be empty.")
					return false
				}

				try {
					await trpc.user.update.mutate(data)
					await user.reload()
					return true
				} catch (error) {
					console.error(error)
					alert.show("Error", "Failed to save account settings. Please try again later.")
					return false
				}
			})
		} else {
			toast.clearUnsavedChanges()
		}
	}, [alert, firstName, lastName, toast, user])

	return (
		<Layout>
			<PageBreadcrumb title="Account" path={[{ title: "Settings", link: routes.settings.index() }]} />
			<Sections>
				<AccountDetails>
					{user.user!.communityProfileId !== null && <ProfileAvatar />}
					<div>
						<AccountName>
							{user.user!.firstName} {user.user!.lastName}
						</AccountName>
						<AccountEmail>{user.user!.email}</AccountEmail>
					</div>
				</AccountDetails>

				<Section>
					<InputGroup>
						<StyledInputField
							label="First name"
							value={firstName}
							onChange={setFirstName}
							autoComplete="given-name"
						/>
						<StyledInputField
							label="Last name"
							value={lastName}
							onChange={setLastName}
							autoComplete="family-name"
						/>
					</InputGroup>
				</Section>

				{user.user!.hasPassword ? <ChangePassword /> : <SetPassword />}
				<Section>
					<SectionTitle>Social sign in</SectionTitle>
					{user.user!.hasGoogleSignin ? (
						<p>Google sign in is connected.</p>
					) : (
						<GoogleSigninButton text="signin" />
					)}
				</Section>
			</Sections>
		</Layout>
	)
}

const Sections = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`

const AccountDetails = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
`

const AccountName = styled.h2`
	font-weight: 600;
	font-size: 18px;
`

const AccountEmail = styled.p`
	font-size: 16px;
	color: #667085;
`

const InputGroup = styled.div`
	width: 100%;
	display: flex;
	gap: 16px;
`

export default AccountSettingsPage
