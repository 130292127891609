import type { OutputData } from "@editorjs/editorjs"
import { type FC, useEffect, useId } from "react"
import styled from "styled-components"

import editorJs from "@forento/shared/utilities/editorJs"

type Props = {
	initialValue: OutputData | null
	setValue(value: { isModified: boolean; value: OutputData } | null): void
}

const CourseLongDescriptionEditor: FC<Props> = ({ initialValue, setValue }) => {
	const editorId = useId()

	useEffect(() => {
		const editor = editorJs({
			holder: editorId,
			data: initialValue ?? undefined,
			onChange: api => api.saver.save().then(value => setValue({ isModified: true, value })),
			placeholder: "Enter long course description here...",
		})

		return () => {
			editor.destroy?.()
		}
	}, [editorId, setValue, initialValue])

	return (
		<Container>
			<div id={editorId} />
		</Container>
	)
}

const Container = styled.div`
	margin: 24px 58px 0;
	max-width: 650px;
`

export default CourseLongDescriptionEditor
