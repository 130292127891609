import { type FC, type ReactNode, useCallback, useMemo, useState } from "react"
import { useBeforeUnload } from "react-router"
import styled from "styled-components"
import randomUUID from "uuid-random"

import { CheckmarkIcon, CloseIcon } from "~/components/Icon"
import LoadingIndicator from "~/components/LoadingIndicator"
import { dangerColor, primaryColor, successColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"
import { startVideoUpload } from "~/utilities/video"

import VideoUploadContext, { type VideoUploadValue, type VideoUpload } from "."

const VideoUploadProvider: FC<{ children?: ReactNode }> = ({ children }) => {
	const [uploadingVideos, setUploadingVideos] = useState<VideoUpload[]>([])

	const handleUnload = useCallback(
		(event: BeforeUnloadEvent) => {
			if (uploadingVideos.length === 0) return

			event.preventDefault()
			const message =
				"Some uploads are still in progress. Reloading or closing the page will cancel the uploads. Are you sure?"
			event.returnValue = message
			return message
		},
		[uploadingVideos],
	)
	useBeforeUnload(handleUnload, { capture: true })

	const value = useMemo<VideoUploadValue>(
		() => ({
			async start(args: { file: File; pageId: number; title: string }) {
				if (uploadingVideos.some(x => x.pageId === args.pageId && x.status === "uploading"))
					throw new Error("Already uploading video to page")

				const id = randomUUID()
				const { bunnyVideoId } = await trpc.course.createVideoByPageId.mutate(args.pageId)
				setUploadingVideos(current => [
					...current,
					{ id, pageId: args.pageId, bunnyVideoId, label: args.title, status: "preparing" },
				])
				await startVideoUpload(args.file, { title: args.title, bunnyVideoId }, async upload => {
					if (upload.status === "uploading") {
						setUploadingVideos(current => {
							const uploads = [...current]
							const uploadIndex = uploads.findIndex(x => x.id === id)
							uploads[uploadIndex] = {
								...uploads[uploadIndex],
								status: "uploading",
								progress: upload.progress,
							}
							return uploads
						})
					} else if (upload.status === "failed") {
						setUploadingVideos(current => {
							const uploads = [...current]
							const uploadIndex = uploads.findIndex(x => x.id === id)
							uploads[uploadIndex] = { ...uploads[uploadIndex], status: "failed" }
							return uploads
						})

						setTimeout(() => {
							setUploadingVideos(current => current.filter(x => x.id !== id))
						}, 5000)
					} else if (upload.status === "completed") {
						await trpc.course.completeVideoByPageId.mutate(args.pageId)
						setUploadingVideos(current => {
							const uploads = [...current]
							const uploadIndex = uploads.findIndex(x => x.id === id)
							uploads[uploadIndex] = { ...uploads[uploadIndex], status: "completed" }
							return uploads
						})

						setTimeout(() => {
							setUploadingVideos(current => current.filter(x => x.id !== id))
						}, 5000)
					}
				})

				return { id }
			},
			getByPageId(pageId) {
				return uploadingVideos.find(x => x.pageId === pageId) ?? null
			},
		}),
		[uploadingVideos],
	)

	return (
		<VideoUploadContext.Provider value={value}>
			{uploadingVideos.length > 0 && (
				<Container>
					{uploadingVideos.map(upload => (
						<Upload key={upload.id} $status={upload.status}>
							{upload.status === "preparing" ? (
								<StyledLoadingIndicator />
							) : upload.status === "uploading" ? (
								<ProgressCircle $progress={upload.progress}>
									<InnerProgressCircle>{Math.floor(upload.progress * 100)}</InnerProgressCircle>
								</ProgressCircle>
							) : upload.status === "completed" ? (
								<Icon as={CheckmarkIcon} />
							) : (
								<Icon as={CloseIcon} />
							)}
							{upload.label}
						</Upload>
					))}
				</Container>
			)}
			{children}
		</VideoUploadContext.Provider>
	)
}

export default VideoUploadProvider

const Container = styled.div`
	position: fixed;
	right: 16px;
	top: 16px;
	width: 300px;
	pointer-events: auto;
	background-color: #fbfaf8;
	border: 1px solid #eeeeed;
	border-radius: 8px;
	box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
	padding: 16px;
	z-index: 10000;
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const Upload = styled.div<{ $status: "preparing" | "uploading" | "failed" | "completed" }>`
	display: flex;
	align-items: center;
	gap: 8px;
	color: ${props =>
		props.$status === "completed" ? successColor : props.$status === "failed" ? dangerColor : "black"};
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
	width: 24px;
	height: 24px;
`

const ProgressCircle = styled.div<{ $progress: number }>`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: conic-gradient(${primaryColor} ${props => props.$progress * 360}deg, #fbfaf8 0deg);
`

const InnerProgressCircle = styled.div`
	position: absolute;
	width: ${24 - 6}px;
	height: ${24 - 6}px;
	border-radius: 50%;
	background-color: #fbfaf8;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 8px;
	line-height: 1;
	font-weight: bold;
`

const Icon = styled.div`
	width: 24px;
	height: 24px;
`
