import type React from "react"
import { useState } from "react"
import { PhoneInput } from "react-international-phone"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"
import { type SearchedDomain } from "@forento/shared/models/domain"

import Button, { SubmitButton } from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import InputField from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import PhoneNumberInputStyles from "~/pages/settings/RegisterDomainPage/PhoneNumberInputStyles"
import routes from "~/utilities/routes"
import { dangerColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import DomainOption from "./DomainOption"

const RegisterDomainPage: React.FC = () => {
	const user = useUser().user!
	const alert = useAlert()

	const [input, setInput] = useState("")
	const [isSearchingDomain, setSearchingDomain] = useState(false)
	const [error, setError] = useState<string>()
	const [domains, setDomains] = useState<SearchedDomain[]>()
	const [purchaseInfo, setPurchaseInfo] = useState<{ price: number; domain: string }>()
	const [phoneNumberInput, setPhoneNumberInput] = useState("")
	const [firstName, setFirstName] = useState(user.firstName)
	const [lastName, setLastName] = useState(user.lastName)
	const [organization, setOrganization] = useState("")
	const [email, setEmail] = useState(user.email)
	const [phoneCountryCode, setPhoneCountryCode] = useState("")
	const [phone, setPhone] = useState("")
	const [address1, setAddress1] = useState("")
	const [address2, setAddress2] = useState("")
	const [postalCode, setPostalCode] = useState("")
	const [city, setCity] = useState("")
	const [state, setState] = useState("")
	const [country, setCountry] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const handleSearchDomain = async () => {
		const normalizedInput = input.trim().toLowerCase()
		if (normalizedInput.length === 0) return

		setSearchingDomain(true)
		const response = await trpc.domain.searchStore.query({ keyword: normalizedInput })
		setSearchingDomain(false)
		setDomains(undefined)
		setPurchaseInfo(undefined)
		setError(undefined)

		if (response.status === "failed") {
			setError("Failed to search for domains. Please try again later.")
			return
		}

		setDomains(response.domains)
	}

	const handleCreateDomain = async () => {
		if (purchaseInfo === undefined) return

		if (firstName.trim().length === 0) {
			return alert.show("Error", "First name is required.")
		}
		if (lastName.trim().length === 0) {
			return alert.show("Error", "Last name is required.")
		}
		if (email.trim().length === 0) {
			return alert.show("Error", "Email is required.")
		}
		if (phoneCountryCode.trim().length === 0) {
			return alert.show("Error", "Phone country code is required.")
		}
		if (phone.trim().length === 0) {
			return alert.show("Error", "Phone number is required.")
		}
		if (address1.trim().length === 0) {
			return alert.show("Error", "Address is required.")
		}
		if (postalCode.trim().length === 0) {
			return alert.show("Error", "Postal code is required.")
		}
		if (city.trim().length === 0) {
			return alert.show("Error", "City is required.")
		}
		if (state.trim().length === 0) {
			return alert.show("Error", "State is required.")
		}
		if (country.trim().length === 0) {
			return alert.show("Error", "Country is required.")
		}

		setSubmitting(true)
		try {
			const response = await trpc.domain.purchase.mutate({
				domain: purchaseInfo.domain,
				contact: {
					firstName,
					lastName,
					organization: organization.trim().length > 0 ? organization : null,
					email,
					phone: {
						countryCode: phoneCountryCode,
						number: phone,
					},
					address1,
					address2: address2.trim().length > 0 ? address2 : null,
					postalCode,
					city,
					state,
					country,
				},
			})
			switch (response.status) {
				case "failed":
					setError("Failed to search for domain. Please try again later.")
					return
				case "invalid-tld":
					setError("We're sorry, but we don't support that top level domain yet.")
					return
				case "taken":
					setError("That domain is already registered by someone else.")
					return
				case "premium":
					setError("We currently don't support premium domains.")
					return
			}
			window.location.href = response.redirectUrl
		} catch {
			setSubmitting(false)
		}
	}

	return (
		<Layout>
			<PageBreadcrumb
				title="Register Domain"
				path={[
					{ title: "Settings", link: routes.settings.index() },
					{ title: "Custom Domain", link: routes.settings.domain.index() },
				]}
			/>
			<Content>
				<Row as={Form} onSubmit={handleSearchDomain}>
					<StyledInputField label="Domain" placeholder="mydomain.com" value={input} onChange={setInput} />
					<SearchButton isLoading={isSearchingDomain} isDisabled={input.trim().length === 0} replaceOnLoading>
						<SearchIcon />
					</SearchButton>
				</Row>
				{error !== undefined && <ErrorText>{error}</ErrorText>}
				{domains !== undefined &&
					domains.map(domain => (
						<DomainOption
							key={domain.domain}
							domain={domain}
							onSelect={() => {
								if (domain.status !== "available") return
								setPurchaseInfo({ domain: domain.domain, price: domain.price })
								setDomains(undefined)
							}}
						/>
					))}
				{purchaseInfo !== undefined && (
					<>
						<PurchaseInfo>
							<Domain>{purchaseInfo.domain}</Domain>
							<DomainPrice>${purchaseInfo.price}/year</DomainPrice>
						</PurchaseInfo>

						<Row>
							<StyledInputField label="First name *" value={firstName} onChange={setFirstName} />
							<StyledInputField label="Last name *" value={lastName} onChange={setLastName} />
						</Row>
						<StyledInputField label="Organization" value={organization} onChange={setOrganization} />
						<StyledInputField label="Email *" value={email} onChange={setEmail} />
						<div>
							<InputLabel>Phone *</InputLabel>
							<PhoneNumberInputStyles />
							<PhoneInput
								value={phoneNumberInput}
								onChange={(phoneNumber, { country }) => {
									setPhoneNumberInput(phoneNumber)
									setPhoneCountryCode(country.dialCode)
									setPhone(phoneNumber.substring(`+${country.dialCode}`.length))
								}}
							/>
						</div>
						<StyledInputField label="Address *" value={address1} onChange={setAddress1} />
						<StyledInputField label="Address 2" value={address2} onChange={setAddress2} />
						<Row>
							<StyledInputField label="Postal code *" value={postalCode} onChange={setPostalCode} />
							<StyledInputField label="City *" value={city} onChange={setCity} />
						</Row>
						<StyledInputField label="State *" value={state} onChange={setState} />
						<div>
							<InputLabel>Country *</InputLabel>
							<Dropdown
								items={[
									{ id: "", title: "-- Select country --" },
									...contactCountries.map(country => ({
										id: country.id,
										title: country.label,
									})),
								]}
								selectedItemId={country}
								onChange={setCountry}
							/>
						</div>
						<PurchaseButton onClick={handleCreateDomain} isLoading={isSubmitting}>
							Purchase domain
						</PurchaseButton>
					</>
				)}
			</Content>
		</Layout>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Row = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 16px;
`

const StyledInputField = styled(InputField)`
	flex: 1;
`

const SearchButton = styled(SubmitButton).attrs({ variant: "primary" })`
	height: 52px;
`

const SearchIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height="24" width="24" fill="white">
		<path d="M24 40 21.9 37.85 34.25 25.5H8V22.5H34.25L21.9 10.15L24 8L40 24Z" />
	</svg>
)

const ErrorText = styled.p`
	color: ${dangerColor};
`

const PurchaseInfo = styled.div`
	display: flex;
	gap: 16px;
	background-color: #fbfbf9;
	border-radius: 12px;
	padding: 22px;
`

const Domain = styled.p`
	font-weight: 600;
	font-size: 16px;
`

const DomainPrice = styled.p`
	font-weight: 600;
	font-size: 16px;
`

const PurchaseButton = styled(Button).attrs({ variant: "primary" })`
	align-self: flex-end;
`

const contactCountries = [
	{ id: "AC", label: "Ascension" },
	{ id: "AD", label: "Andorra" },
	{ id: "AE", label: "United Arab Emirates" },
	{ id: "AF", label: "Afghanistan" },
	{ id: "AG", label: "Antigua and Barbuda" },
	{ id: "AI", label: "Anguilla" },
	{ id: "AL", label: "Albania" },
	{ id: "AM", label: "Armenia" },
	{ id: "AN", label: "Netherlands Antilles" },
	{ id: "AO", label: "Angola" },
	{ id: "AQ", label: "Antarctica" },
	{ id: "AR", label: "Argentina" },
	{ id: "AS", label: "American Samoa" },
	{ id: "AT", label: "Austria" },
	{ id: "AU", label: "Australia" },
	{ id: "AW", label: "Aruba" },
	{ id: "AX", label: "Aland Islands" },
	{ id: "AZ", label: "Azerbaijan" },
	{ id: "BA", label: "Bosnia and Herzegovina" },
	{ id: "BB", label: "Barbados" },
	{ id: "BD", label: "Bangladesh" },
	{ id: "BE", label: "Belgium" },
	{ id: "BF", label: "Burkina Faso" },
	{ id: "BG", label: "Bulgaria" },
	{ id: "BH", label: "Bahrain" },
	{ id: "BI", label: "Burundi" },
	{ id: "BJ", label: "Benin" },
	{ id: "BL", label: "Saint Barthelemy" },
	{ id: "BM", label: "Bermuda" },
	{ id: "BN", label: "Brunei" },
	{ id: "BO", label: "Bolivia" },
	{ id: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
	{ id: "BR", label: "Brazil" },
	{ id: "BS", label: "Bahamas" },
	{ id: "BT", label: "Bhutan" },
	{ id: "BV", label: "Bouvet Island" },
	{ id: "BW", label: "Botswana" },
	{ id: "BY", label: "Belarus" },
	{ id: "BZ", label: "Belize" },
	{ id: "CA", label: "Canada" },
	{ id: "CC", label: "Islands Cocos (Keeling) Islands" },
	{ id: "CD", label: "Congo Zaire" },
	{ id: "CF", label: "Central African Republic" },
	{ id: "CG", label: "Congo" },
	{ id: "CH", label: "Switzerland" },
	{ id: "CI", label: "Ivory Coast" },
	{ id: "CK", label: "Cook Islands" },
	{ id: "CL", label: "Chile" },
	{ id: "CM", label: "Cameroon" },
	{ id: "CN", label: "China" },
	{ id: "CO", label: "Colombia" },
	{ id: "CR", label: "Costa Rica" },
	{ id: "CU", label: "Cuba" },
	{ id: "CV", label: "Cape Verde" },
	{ id: "CW", label: "Curacao" },
	{ id: "CX", label: "Christmas Island" },
	{ id: "CY", label: "Cyprus" },
	{ id: "CZ", label: "Czech Republic" },
	{ id: "DE", label: "Germany" },
	{ id: "DJ", label: "Djibouti" },
	{ id: "DK", label: "Denmark" },
	{ id: "DM", label: "Dominica" },
	{ id: "DO", label: "Dominican (Republic)" },
	{ id: "DZ", label: "Algeria" },
	{ id: "EC", label: "Ecuador" },
	{ id: "EE", label: "Estonia" },
	{ id: "EG", label: "Egypt" },
	{ id: "EH", label: "Western Sahara" },
	{ id: "ER", label: "Erytrea" },
	{ id: "ES", label: "Spain" },
	{ id: "ET", label: "Ethiopia" },
	{ id: "FI", label: "Finland" },
	{ id: "FJ", label: "Fiji" },
	{ id: "FK", label: "Falkland Islands" },
	{ id: "FM", label: "Micronesia" },
	{ id: "FO", label: "Faeroe Islands" },
	{ id: "FR", label: "France" },
	{ id: "GA", label: "Gabon" },
	{ id: "GB", label: "United Kingdom" },
	{ id: "GD", label: "Grenada" },
	{ id: "GE", label: "Georgia" },
	{ id: "GF", label: "French Guiana" },
	{ id: "GG", label: "Guernsey" },
	{ id: "GH", label: "Ghana" },
	{ id: "GI", label: "Gibraltar" },
	{ id: "GL", label: "Greenland" },
	{ id: "GM", label: "Gambia" },
	{ id: "GN", label: "Guinea" },
	{ id: "GP", label: "Guadeloupe" },
	{ id: "GQ", label: "Equatorial Guinea" },
	{ id: "GR", label: "Greece" },
	{ id: "GS", label: "South Georgia and the South Sandwich Islands" },
	{ id: "GT", label: "Guatemala" },
	{ id: "GU", label: "Guam" },
	{ id: "GW", label: "Guinea-Bissau" },
	{ id: "GY", label: "Guyana" },
	{ id: "HK", label: "Hong Kong" },
	{ id: "HM", label: "Australia Heard Island and McDonald Islands" },
	{ id: "HN", label: "Honduras" },
	{ id: "HR", label: "Croatia" },
	{ id: "HT", label: "Haiti" },
	{ id: "HU", label: "Hungary" },
	{ id: "ID", label: "Indonesia" },
	{ id: "IE", label: "Ireland" },
	{ id: "IL", label: "Israel" },
	{ id: "IM", label: "Isle of Man" },
	{ id: "IN", label: "India" },
	{ id: "IO", label: "British Indian Ocean Territory" },
	{ id: "IQ", label: "Iraq" },
	{ id: "IR", label: "Iran (Islamic Rep. of)" },
	{ id: "IS", label: "Iceland" },
	{ id: "IT", label: "Italy" },
	{ id: "JE", label: "Jersey (Islands)" },
	{ id: "JM", label: "Jamaica" },
	{ id: "JO", label: "Jordan" },
	{ id: "JP", label: "Japan" },
	{ id: "KE", label: "Kenya" },
	{ id: "KG", label: "Kyrgystan" },
	{ id: "KH", label: "Cambodia" },
	{ id: "KI", label: "Kiribati" },
	{ id: "KM", label: "Comoros" },
	{ id: "KN", label: "Saint Kitts and Nevis" },
	{ id: "KP", label: "Korea (Democratic People's Republic of)" },
	{ id: "KR", label: "Korea (Republic of)" },
	{ id: "KW", label: "Kuwait" },
	{ id: "KY", label: "Cayman (Islands)" },
	{ id: "KZ", label: "Kazakhstan" },
	{ id: "LA", label: "Laos (People's Democratic Republic of)" },
	{ id: "LB", label: "Lebanon" },
	{ id: "LC", label: "Saint Lucia" },
	{ id: "LI", label: "Liechstenstein" },
	{ id: "LK", label: "Sri Lanka" },
	{ id: "LR", label: "Liberia" },
	{ id: "LS", label: "Lesotho" },
	{ id: "LT", label: "Lithuania" },
	{ id: "LU", label: "Luxembourg" },
	{ id: "LV", label: "Latvia" },
	{ id: "LY", label: "Libya" },
	{ id: "MA", label: "Morocco" },
	{ id: "MC", label: "Monaco" },
	{ id: "MD", label: "Moldova" },
	{ id: "ME", label: "Montenegro" },
	{ id: "MF", label: "Saint Martin (French Part)" },
	{ id: "MG", label: "Madagascar" },
	{ id: "MH", label: "Marshall Islands" },
	{ id: "MK", label: "Macedonia (F.Y.R.O.M.)" },
	{ id: "ML", label: "Mali" },
	{ id: "MM", label: "Myanmar" },
	{ id: "MN", label: "Mongolia" },
	{ id: "MO", label: "Macao" },
	{ id: "MP", label: "Saipan" },
	{ id: "MQ", label: "Martinique" },
	{ id: "MR", label: "Mauritania" },
	{ id: "MS", label: "Montserrat" },
	{ id: "MT", label: "Malta" },
	{ id: "MU", label: "Mauritius" },
	{ id: "MV", label: "Maldives" },
	{ id: "MW", label: "Malawi" },
	{ id: "MX", label: "Mexico" },
	{ id: "MY", label: "Malaysia" },
	{ id: "MZ", label: "Mozambique" },
	{ id: "NA", label: "Namibia" },
	{ id: "NC", label: "New Caledonia" },
	{ id: "NE", label: "Niger" },
	{ id: "NF", label: "Norfolk Island" },
	{ id: "NG", label: "Nigeria" },
	{ id: "NI", label: "Nicaragua" },
	{ id: "NL", label: "Netherlands" },
	{ id: "NO", label: "Norway" },
	{ id: "NP", label: "Nepal" },
	{ id: "NR", label: "Nauru" },
	{ id: "NU", label: "Niue" },
	{ id: "NZ", label: "New Zealand" },
	{ id: "OM", label: "Oman" },
	{ id: "PA", label: "Panama" },
	{ id: "PE", label: "Peru" },
	{ id: "PF", label: "French Polynesia" },
	{ id: "PG", label: "Papua New Guinea" },
	{ id: "PH", label: "Philippines" },
	{ id: "PK", label: "Pakistan" },
	{ id: "PL", label: "Poland" },
	{ id: "PM", label: "Saint Pierre et Miquelon" },
	{ id: "PN", label: "Pitcairn" },
	{ id: "PR", label: "Puerto Rico" },
	{ id: "PS", label: "Palestine" },
	{ id: "PT", label: "Portugal" },
	{ id: "PW", label: "Palau" },
	{ id: "PY", label: "Paraguay" },
	{ id: "QA", label: "Qatar" },
	{ id: "RE", label: "Reunion" },
	{ id: "RO", label: "Romania" },
	{ id: "RS", label: "Serbia" },
	{ id: "RU", label: "Russia" },
	{ id: "RW", label: "Rwanda" },
	{ id: "SA", label: "Saudi Arabia" },
	{ id: "SB", label: "Solomon" },
	{ id: "SC", label: "Seychelles" },
	{ id: "SD", label: "Sudan" },
	{ id: "SE", label: "Sweden" },
	{ id: "SG", label: "Singapore" },
	{ id: "SH", label: "Saint Helena" },
	{ id: "SI", label: "Slovenia" },
	{ id: "SJ", label: "Svalbard and Jan Mayen" },
	{ id: "SK", label: "Slovak (Republic)" },
	{ id: "SL", label: "Sierra Leone" },
	{ id: "SM", label: "San Marino" },
	{ id: "SN", label: "Senegal" },
	{ id: "SO", label: "Somalia" },
	{ id: "SR", label: "Surinam" },
	{ id: "SS", label: "South Sudan" },
	{ id: "ST", label: "Sao Tome and Principe" },
	{ id: "SV", label: "El Salvador" },
	{ id: "SX", label: "Saint Martin (Dutch Part)" },
	{ id: "SY", label: "Syria" },
	{ id: "SZ", label: "Swaziland" },
	{ id: "TC", label: "Turks and Caicos Islands" },
	{ id: "TD", label: "Chad" },
	{ id: "TF", label: "French Southern Territories" },
	{ id: "TG", label: "Togo" },
	{ id: "TH", label: "Thailand" },
	{ id: "TJ", label: "Tajikistan (Republic of)" },
	{ id: "TK", label: "Tokelau" },
	{ id: "TL", label: "Timor-Leste" },
	{ id: "TM", label: "Turkmenistan" },
	{ id: "TN", label: "Tunisia" },
	{ id: "TO", label: "Tonga" },
	{ id: "TP", label: "East Timor" },
	{ id: "TR", label: "Turkey" },
	{ id: "TT", label: "Trinidad and Tobago" },
	{ id: "TV", label: "Tuvalu" },
	{ id: "TW", label: "Taiwan" },
	{ id: "TZ", label: "Tanzania" },
	{ id: "UA", label: "Ukraine" },
	{ id: "UG", label: "Uganda" },
	{ id: "US", label: "United States of America" },
	{ id: "UY", label: "Uruguay" },
	{ id: "UZ", label: "Uzbekistan" },
	{ id: "VA", label: "Holy See (Vatican City State)" },
	{ id: "VC", label: "Saint Vincent and the Grenadines" },
	{ id: "VE", label: "Venezuela" },
	{ id: "VG", label: "British Virgin Islands (Tortola)" },
	{ id: "VI", label: "United States Virgin Islands" },
	{ id: "VN", label: "Vietnam" },
	{ id: "VU", label: "Vanuatu" },
	{ id: "WF", label: "Wallis and Futuna" },
	{ id: "WS", label: "Western Samoa" },
	{ id: "YE", label: "Yemen (Rep. of)" },
	{ id: "YT", label: "Mayotte" },
	{ id: "ZA", label: "South Africa" },
	{ id: "ZM", label: "Zambia" },
	{ id: "ZW", label: "Zimbabwe" },
	{ id: "ZZ", label: "Equatorial Kundu" },
]

export default RegisterDomainPage
