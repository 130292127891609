import dayjs from "dayjs"
import { type FC, useEffect, useState } from "react"
import { Navigate } from "react-router"
import { useSearchParams } from "react-router"
import styled from "styled-components"

import { type PlatformIntent } from "@forento/shared/models/platform"

import Button from "~/components/Button"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { usePlatform, useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

import Layout, { Content, Title } from "../Layout"
import coachingImage from "./images/coaching.svg"
import communityImage from "./images/community.svg"
import courseImage from "./images/course.svg"
import ebookImage from "./images/ebook.svg"
import existingImage from "./images/existing.svg"
import newImage from "./images/new.svg"

type Answer =
	| { id: "hasExistingBusiness"; value: boolean; time: number }
	| { id: "intent"; value: PlatformIntent; time: number }

const SelectIntentPage: FC = () => {
	const user = useUser()
	const platform = usePlatform()
	const [searchParams] = useSearchParams()

	const [startDate, setStartDate] = useState(dayjs())
	const [answers, setAnswers] = useState<Answer[]>([])

	function getAndResetTime(): number {
		const time = Math.floor(dayjs().diff(startDate, "milliseconds") / 10) / 100
		setStartDate(dayjs())
		return time
	}

	const questions = [
		{
			title: "What are you planning to build?",
			options: [
				{
					title: "Sell a course",
					image: courseImage,
					onClick: () => setAnswers(c => [...c, { id: "intent", value: "course", time: getAndResetTime() }]),
				},
				{
					title: "Sell an e-book",
					image: ebookImage,
					onClick: () => setAnswers(c => [...c, { id: "intent", value: "ebook", time: getAndResetTime() }]),
				},
				{
					title: "Create a coaching program",
					image: coachingImage,
					onClick: () =>
						setAnswers(c => [...c, { id: "intent", value: "coaching", time: getAndResetTime() }]),
				},
				{
					title: "Create a community",
					image: communityImage,
					onClick: () =>
						setAnswers(c => [...c, { id: "intent", value: "community", time: getAndResetTime() }]),
				},
			],
		},
		{
			title: "Which user is you?",
			options: [
				{
					title: "I'm building a new business",
					image: newImage,
					onClick: () =>
						setAnswers(c => [...c, { id: "hasExistingBusiness", value: false, time: getAndResetTime() }]),
				},
				{
					title: "I'm expanding an existing business",
					image: existingImage,
					onClick: () =>
						setAnswers(c => [...c, { id: "hasExistingBusiness", value: true, time: getAndResetTime() }]),
				},
			],
		},
	]

	const question = questions[answers.length]

	useEffect(() => {
		if (question !== undefined) return

		async function submitData() {
			await trpc.platform.setIntent.mutate(
				answers.reduce(
					(value, answer) => ({ ...value, [answer.id]: answer.value, [`${answer.id}Time`]: answer.time }),
					{
						intent: undefined!,
						hasExistingBusiness: undefined!,
						intentTime: undefined!,
						hasExistingBusinessTime: undefined!,
					} as Parameters<typeof trpc.platform.setIntent.mutate>[0],
				),
			)
			await user.reload()
		}

		submitData()
	}, [answers, question, user])

	if (platform!.hasChosenIntent) {
		return <Navigate to={searchParams.get("next") ?? routes.account.selectPlan()} />
	}

	return (
		<Layout>
			{question !== undefined ? (
				<Content $uncappedWidth>
					<Title>{question.title}</Title>
					<DesktopOptions>
						{question.options.map(option => (
							<Option key={option.title}>
								<OptionImage alt="Illustration" src={option.image} />
								<TitleContainer>
									<OptionTitle>{option.title}</OptionTitle>
								</TitleContainer>
								<Button variant="primary" onClick={option.onClick}>
									Select
								</Button>
							</Option>
						))}
					</DesktopOptions>
					<MobileOptions>
						{question.options.map(option => (
							<Button key={option.title} variant="primary" onClick={option.onClick}>
								{option.title}
							</Button>
						))}
					</MobileOptions>
				</Content>
			) : (
				<PartialLoadingPage />
			)}
		</Layout>
	)
}

const DesktopOptions = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	justify-content: center;

	@media (max-width: 599px) {
		display: none;
	}
`

const MobileOptions = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;

	@media (min-width: 600px) {
		display: none;
	}
`

const Option = styled.div`
	flex: 0 0 250px;
	background-color: white;
	padding: 16px;
	box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 5px 3px;
	border: 2px solid rgba(0, 0, 0, 0.03);
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const OptionImage = styled.img`
	align-self: center;
	width: 80%;
	height: 150px;
	object-fit: contain;
`

const TitleContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`

const OptionTitle = styled.h2`
	font-size: 20px;
	font-weight: 600;
	text-align: center;
`

export default SelectIntentPage
