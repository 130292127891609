import { type FC, useEffect } from "react"
import { Navigate, useNavigate, useSearchParams } from "react-router"

import { subscriptionPaymentIntervals } from "@forento/shared/models/payment"
import { parseNumber } from "@forento/shared/utilities/number"

import FullLoadingPage from "~/components/FullLoadingPage"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

const StartPlanSubscriptionPage: FC = () => {
	const platform = usePlatform()!
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const planId = parseNumber(searchParams.get("plan") ?? "")
	const paymentInterval = subscriptionPaymentIntervals.find(x => x === searchParams.get("payment-interval")) ?? null

	const isValid = planId !== null && paymentInterval !== null && platform.plan.type !== "regular"

	useEffect(() => {
		const abortController = new AbortController()

		async function run() {
			if (!isValid) return

			const { redirectUrl } = await trpc.payment.creator.setPlan.mutate(
				{
					planId,
					paymentInterval,
					redirect: { cancel: routes.settings.billing.plans(), success: routes.main.index() },
					source: "email",
				},
				{ signal: abortController.signal },
			)
			if (redirectUrl) {
				window.location.href = redirectUrl
			} else {
				navigate(routes.account.planStarted())
			}
		}

		run()

		return () => {
			abortController.abort()
		}
	}, [isValid, navigate, paymentInterval, planId])

	if (!isValid) return <Navigate to={routes.main.index()} />

	return <FullLoadingPage />
}

export default StartPlanSubscriptionPage
