import { useEffect, useState } from "react"

import Button from "~/components/Button"
import InputField, { useRichTextArea } from "~/components/InputField"

import Attachments from "./Attachments"
import Image from "./Image"
import Links from "./Links"
import { ButtonsGroup, ButtonsRow } from "./styles"

interface Props {
	page: {
		id: number
		title: string
		text: string | null
		image: {
			filePath: string
			contain: boolean
		} | null
		attachments: {
			id: number
			fileType: string
			label: string
			url: string
		}[]
		links: {
			id: number
			label: string
			url: string
		}[]
	}
	onClose: () => void
	onSavePage: (data: { title: string; text: string | null; containImage: boolean }) => void
	onDeletePage: () => void
}

const EditTextPageModalContent: React.FC<Props> = ({ page, onClose, onSavePage, onDeletePage }) => {
	const [title, setTitle] = useState(page.title)
	const text = useRichTextArea({ label: "Text", initialValue: page.text })
	const [containImage, setContainImage] = useState(page.image?.contain ?? false)

	useEffect(() => {
		setTitle(page.title)
		setContainImage(page.image?.contain ?? false)
	}, [page])

	return (
		<>
			<InputField label="Title" value={title} onChange={setTitle} />
			{text.element}
			<Image
				pageId={page.id}
				filePath={page.image?.filePath ?? null}
				containImage={containImage}
				setContainImage={setContainImage}
			/>
			<Attachments pageId={page.id} attachments={page.attachments} />
			<Links pageId={page.id} links={page.links} />
			<ButtonsRow>
				<ButtonsGroup>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant="primary"
						isDisabled={title.trim().length === 0}
						onClick={() => onSavePage({ title, text: text.exportEditorState(), containImage })}
					>
						Save
					</Button>
				</ButtonsGroup>
				<Button variant="primary-danger" onClick={onDeletePage}>
					Delete
				</Button>
			</ButtonsRow>
		</>
	)
}

export default EditTextPageModalContent
