import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { type CSSProperties, type FC } from "react"
import styled, { css } from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"
import Tooltip from "@forento/shared/components/Tooltip"
import { type Bundle as BundleType } from "@forento/shared/models/bundle"

import Button from "~/components/Button"
import { DeleteIcon, DragIndicatorIcon, EditIcon } from "~/components/Icon"
import { useAlert } from "~/contexts/AlertContext"
import routes from "~/utilities/routes"
import { dangerColor } from "~/utilities/styles"

type Props = { bundle: BundleType; onDelete(): void }
const Bundle: FC<Props> = ({ bundle, onDelete }) => {
	const alert = useAlert()
	const { setNodeRef, transform, transition, attributes, listeners } = useSortable({ id: bundle.id })

	const style: CSSProperties = { transform: CSS.Transform.toString(transform), transition }

	const handleDelete = async () => {
		const dialog = await alert.confirm(
			"Delete bundle",
			`Are you sure you want to delete the bundle *${bundle.name}*? All existing purchases will be unaffected.`,
		)
		if (!dialog.result) return

		// TODO: Delete bundle

		dialog.close()
		onDelete()
	}

	return (
		<Container ref={setNodeRef} style={style}>
			{bundle.thumbnailFilePath && (
				<ThumbnailContainer>
					<AspectRatio aspectRatio={16 / 9} width={{ unit: "percent", value: 100 }}>
						<Thumbnail alt="Thumbnail" src={bundle.thumbnailFilePath} />
					</AspectRatio>
				</ThumbnailContainer>
			)}
			<Details>
				<Title>{bundle.name}</Title>
				{bundle.price !== null && <Products>{bundle.items.map(x => x.name).join(", ")}</Products>}
			</Details>
			<ActionsContainer>
				<Tooltip tooltip="Edit">
					<ActionButton onClick={routes.bundle.detail(bundle.id)}>
						<EditIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Delete">
					<ActionButton isDanger onClick={handleDelete}>
						<DeleteIcon />
					</ActionButton>
				</Tooltip>
				<DragButton {...attributes} {...listeners}>
					<DragIndicatorIcon />
				</DragButton>
			</ActionsContainer>
		</Container>
	)
}

const Container = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	padding: 22px;
	display: flex;
	gap: 16px;
`

const ThumbnailContainer = styled.div`
	flex: 0 0 250px;
`

const Thumbnail = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const Details = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 20px;
	text-align: left;
`

const Products = styled.p`
	font-size: 16px;
	margin-top: 8px;
`

const ActionsContainer = styled.div`
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	gap: 8px;
`

const ActionButton = styled(Button)<{ isDanger?: boolean }>`
	width: 24px;
	height: 24px;

	${props =>
		props.isDanger &&
		css`
			color: ${dangerColor};
		`}
`

const DragButton = styled.div`
	width: 24px;
	height: 24px;
	cursor: grab;
`

export default Bundle
