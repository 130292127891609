import { type FC } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"

import illustration from "./illustration.svg"

const LockedFeature: FC<{ className?: string }> = ({ className }) => (
	<Container className={className}>
		<Illustration alt="Illustration" src={illustration} />
		<Title>This feature is locked</Title>
		<Text>In order to use this feature you need to upgrade your plan.</Text>
		<Button variant="primary" onClick={routes.settings.billing.plans()}>
			Browse plans
		</Button>
	</Container>
)

const Container = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eeeeed;
	border-radius: 8px;
	padding: 42px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
`

const Illustration = styled.img`
	width: 100px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 20px;
`

const Text = styled.p`
	color: ${lightTextColor};
	text-align: center;
	max-width: 500px;
`

export default LockedFeature
