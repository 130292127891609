import * as Sentry from "@sentry/react"
import "array.prototype.tosorted/auto"
import dayjs from "dayjs"
import dayjsUtc from "dayjs/plugin/utc"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import React from "react"
import { createRoot } from "react-dom/client"

import "@forento/shared/assets/lexical.css"

import App from "~/App"

dayjs.extend(dayjsUtc)

if (!CONFIG.isDevelopment) {
	Sentry.init({
		dsn: "https://cf3f5fe1a7974d4c875655649b33bde9@o794413.ingest.sentry.io/5894331",
		replaysOnErrorSampleRate: 0.25,
		tracesSampleRate: 0.05,
		integrations: [
			Sentry.replayIntegration({
				beforeErrorSampling(event) {
					const error = event.exception?.values?.[0].value
					if (error?.includes("Failed to fetch")) return false
					return true
				},
			}),
			Sentry.browserTracingIntegration(),
		],
	})

	posthog.init("phc_RpYVUb6Io9CZkERfTEEJhs7SMGiaPAYmBTfRJz7R44t", {
		api_host: "/ph",
		ui_host: "https://eu.posthog.com",
		persistence: "localStorage",
	})
}

document.addEventListener("DOMContentLoaded", () => {
	createRoot(document.getElementById("root")!).render(
		<React.StrictMode>
			<PostHogProvider client={posthog}>
				<App />
			</PostHogProvider>
		</React.StrictMode>,
	)
})
