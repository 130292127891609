import "array.prototype.tosorted/auto"
import { jwtDecode } from "jwt-decode"
import { usePostHog } from "posthog-js/react"
import { type FC, type ReactNode, useEffect, useLayoutEffect, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { createBrowserRouter, Navigate, RouterProvider, useLocation, useSearchParams } from "react-router"
import { createGlobalStyle } from "styled-components"

import "@forento/shared/assets/lexical.css"
import { combineContextProviders } from "@forento/shared/utilities/component"
import { setCookie } from "@forento/shared/utilities/cookie"

import appRoutes from "~/appRoutes"
import AdminPanel from "~/components/AdminPanel"
import CookieConsent from "~/components/CookieConsent"
import ErrorBoundary from "~/components/ErrorBoundary"
import FullLoadingPage from "~/components/FullLoadingPage"
import AlertContextProvider from "~/contexts/AlertContext/Provider"
import GoogleSignInContextProvider from "~/contexts/GoogleSignInContext/Provider"
import ToastContextProvider from "~/contexts/ToastContext/Provider"
import { useUser } from "~/contexts/UserContext"
import UserContextProvider from "~/contexts/UserContext/Provider"
import VideoUploadProvider from "~/contexts/VideoUploadContext/Provider"
import normalize from "~/utilities/normalize"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

const GlobalStyle = createGlobalStyle`
	${normalize}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	h1, h2, h3, h4, h5, h6, p, button {
		margin: 0;
		padding: 0;
		font-weight: 400;
	}

	body {
		min-height: 100vh;
		font-family: "Gilroy", sans-serif;
		font-size: 16px;
		line-height: 1.42857;
	}
`

export type Authorization = "none" | "require" | "forbid"

const AppContextProviders = combineContextProviders([
	HelmetProvider,
	UserContextProvider,
	AlertContextProvider,
	ToastContextProvider,
	GoogleSignInContextProvider,
	VideoUploadProvider,
])

const RouterWrapper: FC<{ authorization: Authorization; children: ReactNode }> = ({ authorization, children }) => {
	const [swrClient] = useState(() => swr.createClient())

	return (
		<swr.Provider client={swrClient}>
			<AppContextProviders>
				{!CONFIG.isDevelopment && (
					<Helmet>
						<script
							defer
							data-domain="app.forento.io,rollup.forento.io"
							src="/plausible/script.js"
							data-api="/plausible/event"
						/>
					</Helmet>
				)}
				<CookieConsent />
				{CONFIG.isDevelopment && <AdminPanel />}
				<Content authorization={authorization}>{children}</Content>
			</AppContextProviders>
		</swr.Provider>
	)
}

const Content: FC<{ authorization: Authorization; children: ReactNode }> = ({ authorization, children }) => {
	const location = useLocation()
	const user = useUser()
	const [searchParams] = useSearchParams()
	const posthog = usePostHog()

	useEffect(() => {
		window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
	}, [location.pathname])

	useLayoutEffect(() => {
		const impersonateParam = searchParams.get("impersonate")
		if (impersonateParam) {
			const expireDate = jwtDecode(impersonateParam).exp
			setCookie({
				name: "impersonate-session",
				value: impersonateParam,
				path: "/",
				sameSite: true,
				secure: true,
				expireDate: expireDate ? new Date(expireDate * 1000) : undefined,
			})
			searchParams.delete("impersonate")
			user.reload()
		}
	}, [searchParams, user])

	useEffect(() => {
		if (user.user === undefined || user.user.isImpersonating || CONFIG.isDevelopment) return

		const timers: NodeJS.Timeout[] = []
		let activeDuration = 0
		let backgroundDuration = 0
		trpc.activity.createSession.mutate().then(session => {
			timers.push(
				setInterval(() => {
					if (window.document.hidden) backgroundDuration++
					else activeDuration++
				}, 1_000),
			)
			timers.push(
				setInterval(() => {
					trpc.activity.updateSession
						.mutate({ id: session.id, activeDuration, backgroundDuration })
						.catch(() => {})
				}, 10_000),
			)
		})

		return () => {
			timers.forEach(timer => clearInterval(timer))
		}
	}, [user.user])

	useEffect(() => {
		const sourceParam = searchParams.get("s")
		if (sourceParam === null) return

		const domain = CONFIG.isDevelopment ? "localhost" : ".forento.io"
		setCookie({ name: "s", value: sourceParam, maxAge: 365 * 24 * 60 * 60, domain, path: "/" })
	}, [searchParams])

	useEffect(() => {
		if (CONFIG.isDevelopment || !user.user?.hash || user.user.isImpersonating) return

		window.Intercom("update", {
			user_id: user.user.id.toString(),
			user_hash: user.user.hash,
			name: `${user.user.firstName} ${user.user.lastName}`,
			email: user.user.email,
			created_at: user.user.registerDate.getTime() / 1000,
		})
	}, [user.user, location.pathname])

	useEffect(() => {
		if (!user.user?.id || user.user.isImpersonating) {
			posthog.reset()
			return
		}
		posthog.identify(user.user.id.toString(), {
			email: user.user.email,
			name: `${user.user.firstName} ${user.user.lastName}`,
			created_at: user.user.registerDate.toISOString(),
		})
	}, [posthog, user.user])

	if (user.isLoading) return <FullLoadingPage />

	if (user.user && !user.user.platform && location.pathname !== routes.account.createPlatform()) {
		return <Navigate to={routes.account.createPlatform()} replace />
	}

	if (
		user.user &&
		user.user.platform &&
		!user.user.platform.contactEmail &&
		location.pathname !== routes.account.setContactEmail()
	) {
		return <Navigate to={routes.account.setContactEmail()} replace />
	}

	if (
		user.user &&
		user.user.platform &&
		user.user.platform.contactEmail &&
		!user.user.platform.hasChosenFindSource &&
		location.pathname !== routes.account.selectFindSource()
	) {
		return (
			<Navigate
				to={`${routes.account.selectFindSource()}?next=${encodeURIComponent(location.pathname)}`}
				replace
			/>
		)
	}

	if (
		user.user &&
		user.user.platform &&
		user.user.platform.contactEmail &&
		user.user.platform.hasChosenFindSource &&
		!user.user.platform.hasChosenIntent &&
		location.pathname !== routes.account.selectIntent()
	) {
		return (
			<Navigate to={`${routes.account.selectIntent()}?next=${encodeURIComponent(location.pathname)}`} replace />
		)
	}

	if (user.user === undefined && authorization === "require")
		return (
			<Navigate
				to={`${routes.account.signin()}?next=${encodeURIComponent(location.pathname + location.search)}`}
				replace
			/>
		)

	if (user.user !== undefined && authorization === "forbid") return <Navigate to={routes.main.index()} replace />

	return children
}

const router = createBrowserRouter(
	appRoutes.map(route => ({
		path: route.path,
		element: (
			<RouterWrapper authorization={route.auth}>
				<route.component />
			</RouterWrapper>
		),
	})),
)

const App: FC = () => (
	<>
		<GlobalStyle />
		<ErrorBoundary>
			<RouterProvider router={router} />
		</ErrorBoundary>
	</>
)

export default App
