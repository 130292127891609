import { useEffect, useState } from "react"
import styled from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"
import VideoPlayer from "@forento/shared/components/VideoPlayer"
import { type VideoCaptionLanguageCode } from "@forento/shared/models/video"

import Button from "~/components/Button"
import InputField, { useRichTextArea } from "~/components/InputField"
import LoadingIndicator from "~/components/LoadingIndicator"
import VideoCaptions from "~/components/VideoCaptions"
import VideoUploader from "~/components/VideoUploader"
import { useVideoUploader } from "~/contexts/VideoUploadContext"
import { arrayBufferToBase64 } from "~/utilities/buffer"
import trpc from "~/utilities/trpc"

import Attachments from "./Attachments"
import Links from "./Links"
import { ButtonsGroup, ButtonsRow, DeleteMediaButton, DeleteMediaIcon } from "./styles"

interface Props {
	page: {
		id: number
		title: string
		text: string | null
		video:
			| { host: "apiVideo"; apiVideoId: string }
			| { host: "bunny"; bunnyVideoId: string; captions: VideoCaptionLanguageCode[] }
			| null
		attachments: {
			id: number
			fileType: string
			label: string
			url: string
		}[]
		links: {
			id: number
			label: string
			url: string
		}[]
	}
	onClose: () => void
	onSavePage: (data: {
		title: string
		text: string | null
		addCaptions: { language: VideoCaptionLanguageCode; base64: string }[]
		removeCaptions: VideoCaptionLanguageCode[]
	}) => void
	onDeletePage: () => void
}

type Video = { host: "apiVideo"; apiVideoId: string } | { host: "bunny"; bunnyVideoId: string }

const EditVideoPageModalContent: React.FC<Props> = ({ page, onClose, onSavePage, onDeletePage }) => {
	const videoUploader = useVideoUploader()

	const [title, setTitle] = useState(page.title)
	const text = useRichTextArea({ label: "Text" })
	const [video, setVideo] = useState<Video | null>(page.video)
	const [addCaptions, setAddCaptions] = useState<{ language: VideoCaptionLanguageCode; file: File }[]>([])
	const [removeCaptions, setRemoveCaptions] = useState<VideoCaptionLanguageCode[]>([])
	const [isDeletingVideo, setDeletingVideo] = useState(false)

	const uploadingVideo = videoUploader.getByPageId(page.id)

	const setText = text.set
	useEffect(() => {
		setTitle(page.title)
		setText(page.text)
		setVideo(page.video)
		setAddCaptions([])
		setRemoveCaptions([])
	}, [page, setText])

	useEffect(() => {
		if (uploadingVideo?.status === "completed") {
			setVideo({ host: "bunny", bunnyVideoId: uploadingVideo.bunnyVideoId })
		} else if (uploadingVideo?.status === "failed") {
			setVideo(null)
		}
	}, [uploadingVideo?.bunnyVideoId, uploadingVideo?.status])

	async function handleDownloadVideo() {
		try {
			const url = await trpc.course.getVideoDownloadUrlByPageId.query(page.id)
			window.open(url, "_blank")
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<>
			<InputField label="Title" value={title} onChange={setTitle} />
			{text.element}
			<AspectRatio width={{ unit: "percent", value: 100 }} aspectRatio={16 / 9}>
				{video !== null ? (
					<PlayerContainer>
						<VideoPlayer video={video} />
						<DeleteMediaButton
							onClick={async () => {
								if (isDeletingVideo) return
								setDeletingVideo(true)
								try {
									await trpc.course.deletePageVideo.mutate(page.id)
									setVideo(null)
								} finally {
									setDeletingVideo(false)
								}
							}}
						>
							{!isDeletingVideo ? (
								<DeleteMediaIcon />
							) : (
								<DeleteMediaIcon as={LoadingIndicator} color="white" />
							)}
						</DeleteMediaButton>
					</PlayerContainer>
				) : (
					<VideoUploader
						onUpload={async file => {
							await videoUploader.start({
								file,
								pageId: page.id,
								title: title.length > 0 ? title : "Untitled page",
							})
						}}
						progress={uploadingVideo?.status === "uploading" ? uploadingVideo.progress : null}
					/>
				)}
			</AspectRatio>
			{page.video?.host !== "apiVideo" && (
				<VideoCaptions
					captions={page.video?.captions ?? []}
					addCaptions={addCaptions}
					removeCaptions={removeCaptions}
					setAddCaptions={setAddCaptions}
					setRemoveCaptions={setRemoveCaptions}
				/>
			)}
			{video?.host === "bunny" && (
				<Button variant="secondary" onClick={handleDownloadVideo}>
					Download video
				</Button>
			)}
			<Attachments pageId={page.id} attachments={page.attachments} />
			<Links pageId={page.id} links={page.links} />
			<ButtonsRow>
				<ButtonsGroup>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant="primary"
						isDisabled={title.trim().length === 0}
						onClick={async () =>
							onSavePage({
								title,
								text: text.exportEditorState(),
								addCaptions: await Promise.all(
									addCaptions.map(async x => ({
										language: x.language,
										base64: arrayBufferToBase64(await x.file.arrayBuffer()),
									})),
								),
								removeCaptions,
							})
						}
					>
						Save
					</Button>
				</ButtonsGroup>
				<Button variant="primary-danger" onClick={onDeletePage}>
					Delete
				</Button>
			</ButtonsRow>
		</>
	)
}

const PlayerContainer = styled.div`
	position: relative;
`

export default EditVideoPageModalContent
