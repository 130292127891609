import { type FC, useState } from "react"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"

import Button, { SubmitButton } from "~/components/Button"
import { DeleteIcon, ExtendTimeIcon } from "~/components/Icon"
import InputField from "~/components/InputField"
import Layout, { PageBreadcrumb, PageHeader } from "~/components/Layout"
import { DefaultModal, ModalButtons, ModalInputs, ModalText, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import { useAlert } from "~/contexts/AlertContext"
import { usePlatform, useUser } from "~/contexts/UserContext"
import { toRelativeDateString } from "~/utilities/date"
import routes from "~/utilities/routes"
import { dangerColor } from "~/utilities/styles"
import trpc, { swr } from "~/utilities/trpc"

const TeamSettingsPage: FC = () => {
	const user = useUser()
	const platform = usePlatform()!
	const alert = useAlert()

	const [inviteTeamMemberModal, setInviteTeamMemberModal] = useState<{
		isOpen: boolean
		email: string
		isSubmitting: boolean
	}>({
		isOpen: false,
		email: "",
		isSubmitting: false,
	})
	const [removeTeamMemberModal, setRemoveTeamMemberModal] = useState<{
		isOpen: boolean
		user?: { id: number; fullName: string }
	}>({
		isOpen: false,
	})

	const { data: creators, error, mutate } = swr.platform.listCreators.useSWR()

	const closeRemoveTeamMemberModal = () => setRemoveTeamMemberModal(current => ({ ...current, isOpen: false }))

	const handleInviteTeamMember = async () => {
		if (inviteTeamMemberModal.email.trim().length === 0) return

		setInviteTeamMemberModal(c => ({ ...c, isSubmitting: true }))

		try {
			await trpc.platform.inviteCreator.mutate({ email: inviteTeamMemberModal.email.trim() })
			setInviteTeamMemberModal({ isOpen: false, email: "", isSubmitting: false })
		} catch (error) {
			console.error(error)
			await alert.show(
				"Error",
				"Failed to invite team member. Make sure you have entered a valid e-mail address and try again.",
			)
		} finally {
			setInviteTeamMemberModal(c => ({ ...c, isSubmitting: false }))
		}

		await Promise.all([user.reload(), mutate()])
	}

	const handleRemoveTeamMember = async () => {
		if (removeTeamMemberModal.user?.id === undefined) return

		await trpc.platform.removeCreator.mutate(removeTeamMemberModal.user.id)
		closeRemoveTeamMemberModal()
		await mutate()
	}

	const handleExtendInviteExpiryTime = async (email: string) => {
		const prompt = await alert.confirm(
			"Extend invite",
			`Are you sure you want to extend the expiry date of the invite to ${email}?`,
		)
		if (!prompt.result) return

		await trpc.platform.extendCreatorInvite.mutate({ email })
		prompt.close()
		await mutate()
	}

	const handleRemoveInvite = async (email: string) => {
		const prompt = await alert.confirm("Delete invite", `Are you sure you want to delete the invite to ${email}?`)
		if (!prompt.result) return

		await trpc.platform.deleteCreatorInvite.mutate({ email })
		prompt.close()
		await mutate()
	}

	return (
		<Layout>
			<DefaultModal isOpen={inviteTeamMemberModal.isOpen} onSubmit={handleInviteTeamMember}>
				<ModalTitle>Invite team member</ModalTitle>
				{platform.hasReachedAdminsLimit ? (
					<>
						<ModalText>
							You have reached the maximum number of team members. You need to upgrade your plan to invite
							more.
						</ModalText>
						<ModalButtons>
							<Button
								variant="secondary"
								onClick={() => setInviteTeamMemberModal(current => ({ ...current, isOpen: false }))}
								isDisabled={inviteTeamMemberModal.isSubmitting}
							>
								Close
							</Button>
							<Button variant="primary" onClick={routes.settings.billing.plans()}>
								Go to plans
							</Button>
						</ModalButtons>
					</>
				) : (
					<>
						<ModalInputs>
							<InputField
								label="E-mail address"
								value={inviteTeamMemberModal.email}
								onChange={email => setInviteTeamMemberModal(current => ({ ...current, email }))}
							/>
						</ModalInputs>
						<ModalButtons>
							<Button
								variant="secondary"
								onClick={() => setInviteTeamMemberModal(current => ({ ...current, isOpen: false }))}
								isDisabled={inviteTeamMemberModal.isSubmitting}
							>
								Cancel
							</Button>
							<SubmitButton variant="primary" isLoading={inviteTeamMemberModal.isSubmitting}>
								Invite team member
							</SubmitButton>
						</ModalButtons>
					</>
				)}
			</DefaultModal>

			<DefaultModal isOpen={removeTeamMemberModal.isOpen} onSubmit={handleRemoveTeamMember}>
				<ModalTitle>Remove team member</ModalTitle>
				<ModalText>
					Are you sure you want to remove the team member{" "}
					<strong>{removeTeamMemberModal.user?.fullName}</strong>?
				</ModalText>
				<ModalButtons>
					<Button variant="secondary" onClick={closeRemoveTeamMemberModal}>
						Cancel
					</Button>
					<SubmitButton variant="primary-danger">Remove team member</SubmitButton>
				</ModalButtons>
			</DefaultModal>

			<PageHeader>
				<PageBreadcrumb path={[{ title: "Settings", link: routes.settings.index() }]} title="Team Members" />
				<Button
					variant="primary"
					onClick={() => setInviteTeamMemberModal(current => ({ ...current, isOpen: true }))}
				>
					Invite team member
				</Button>
			</PageHeader>
			{error ? (
				<p>Failed to load team members.</p>
			) : !creators ? (
				<PartialLoadingPage />
			) : (
				<Content>
					<Table
						columns={[
							{ label: "Name", flex: { grow: 1, basis: 0 } },
							{ label: "Actions", flex: { grow: 0, basis: 150 } },
						]}
						data={creators.users.map(x => [
							<Name>
								{x.firstName} {x.lastName}
							</Name>,
							x.id !== user.user!.id && (
								<Actions>
									<ActionButton
										onClick={() =>
											setRemoveTeamMemberModal({
												isOpen: true,
												user: {
													id: x.id,
													fullName: `${x.firstName} ${x.lastName}`,
												},
											})
										}
									>
										<DeleteIcon />
									</ActionButton>
								</Actions>
							),
						])}
					/>
					{creators.invited.length > 0 && (
						<div>
							<Subtitle>Invited members</Subtitle>
							<Table
								columns={[
									{ label: "Email", flex: { grow: 1, basis: 0 } },
									{ label: "Expires", flex: { grow: 0, basis: 150 } },
									{ label: "Actions", flex: { grow: 0, basis: 150 } },
								]}
								data={creators.invited.map(x => [
									<Name>{x.email}</Name>,
									toRelativeDateString(x.expireDate),
									<Actions>
										<Tooltip tooltip="Extend time">
											<ActionButton onClick={() => handleExtendInviteExpiryTime(x.email)}>
												<ExtendTimeIcon />
											</ActionButton>
										</Tooltip>
										<Tooltip tooltip="Delete invite">
											<ActionButton $isDanger onClick={() => handleRemoveInvite(x.email)}>
												<DeleteIcon />
											</ActionButton>
										</Tooltip>
									</Actions>,
								])}
							/>
						</div>
					)}
				</Content>
			)}
		</Layout>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`

const Name = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`

const Actions = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`

const ActionButton = styled(Button)<{ $isDanger?: boolean }>`
	width: 24px;
	height: 24px;
	color: ${props => (props.$isDanger ? dangerColor : "#828282")};
`

const Subtitle = styled.h2`
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 16px;
`

export default TeamSettingsPage
