import dayjs from "dayjs"
import { type FC, useReducer, useState } from "react"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import Button, { SubmitButton } from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import Dropdown from "~/components/Dropdown"
import InputField from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import trpc from "~/utilities/trpc"

const discountTypes = ["percent", "amount"] as const
const durations = [
	{ id: "once", label: "Once" },
	{ id: "repeating", label: "Repeating" },
	{ id: "forever", label: "Forever" },
] as const

type DiscountType = (typeof discountTypes)[number]
type Duration = (typeof durations)[number]

interface Props {
	isOpen: boolean
	onClose(): void
	onSubmit(): void
}

const CreateCodeModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
	const platform = useUser().user!.platform!
	const alert = useAlert()

	const discountTypeNames: Record<DiscountType, string> = { amount: platform.currency, percent: "%" }

	const [name, setName] = useState("")
	const [code, setCode] = useReducer((_: unknown, value: string) => value.trim().toUpperCase(), "")
	const [discount, setDiscount] = useState("10")
	const [discountType, setDiscountType] = useState<DiscountType>("percent")
	const [duration, setDuration] = useState<Duration["id"]>("once")
	const [durationInMonths, setDurationInMonths] = useState("1")
	const [isMaxRedemptionsEnabled, setMaxRedemptionsEnabled] = useState(false)
	const [maxRedemptions, setMaxRedemptions] = useState("0")
	const [isRedemptionDateLimited, setRedemptionDateLimited] = useState(false)
	const [redemptionDate, setRedemptionDate] = useState(dayjs().add(1, "month").format("YYYY-MM-DDT00:00"))

	const [isSubmitting, setSubmitting] = useState(false)

	const validatedData = (() => {
		const data = {
			name: name.trim(),
			code: code.trim(),
			duration,
			durationInMonths: parseNumber(durationInMonths),
			discount: parseNumber(discount),
			discountType,
			maxRedemptions: isMaxRedemptionsEnabled ? parseNumber(maxRedemptions) : undefined,
			lastRedemptionDate: isRedemptionDateLimited ? new Date(redemptionDate) : undefined,
		}
		if (
			data.name.length === 0 ||
			data.code.length === 0 ||
			data.durationInMonths === null ||
			data.discount === null ||
			data.maxRedemptions === null ||
			data.lastRedemptionDate === null
		) {
			return null
		}
		return {
			name: data.name,
			code: data.code,
			discount: {
				type: data.discountType,
				value: data.discount,
			},
			duration:
				data.duration === "repeating"
					? { type: "repeating" as const, months: data.durationInMonths }
					: { type: data.duration },
			maxRedemptions: data.maxRedemptions ?? null,
			lastRedemptionDate: data.lastRedemptionDate ?? null,
		}
	})()

	const handleSubmit = async () => {
		if (validatedData === null) return

		if (validatedData.name.length > 40) {
			await alert.show("Name too long", "The name can't be longer than 40 characters.")
			return null
		}
		if (validatedData.lastRedemptionDate && validatedData.lastRedemptionDate < new Date()) {
			await alert.show("Invalid date", "The last redemption date can't be in the past.")
			return
		}

		setSubmitting(true)
		try {
			const { status } = await trpc.payment.stripe.createDiscountCode.mutate(validatedData)
			if (status !== "success") {
				if (status === "invalid-code") {
					await alert.show("Invalid code", "That code is invalid. It can only contain letters and numbers.")
				}
				if (status === "code-taken") {
					await alert.show("Code already taken", "That code is already in use for another discount code.")
				}
				return
			}
			onSubmit()
			setName("")
			setCode("")
			setDiscount("10")
			setDiscountType("percent")
			setDuration("once")
			setDurationInMonths("1")
			setMaxRedemptionsEnabled(false)
			setMaxRedemptions("0")
			setRedemptionDateLimited(false)
			setRedemptionDate(dayjs().add(1, "month").format("YYYY-MM-DDT00:00"))
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSubmit}>
			<ModalTitle>Create discount code</ModalTitle>
			<ModalInputs>
				<InputField label="Name" value={name} onChange={setName} />
				<InputField label="Code" value={code} onChange={setCode} />
				<DiscountContainer>
					<DiscountValue label="Discount" value={discount} onChange={setDiscount} />
					<DiscountUnit
						items={discountTypes.map(type => ({ id: type, title: discountTypeNames[type] }))}
						selectedItemId={discountType}
						onChange={type => setDiscountType(discountTypes.find(x => x === type) ?? "percent")}
					/>
				</DiscountContainer>
				<Group>
					<Checkbox
						label="Limit number of redemptions"
						isChecked={isMaxRedemptionsEnabled}
						onChange={setMaxRedemptionsEnabled}
					/>
					<InputField
						label="Max redemptions"
						value={isMaxRedemptionsEnabled ? maxRedemptions : ""}
						onChange={setMaxRedemptions}
						disabled={!isMaxRedemptionsEnabled}
					/>
				</Group>
				<Group>
					<Dropdown
						items={durations.map(duration => ({ id: duration.id, title: duration.label }))}
						selectedItemId={duration}
						onChange={id => setDuration(durations.find(x => x.id === id)?.id ?? "once")}
					/>
					{duration === "repeating" && (
						<InputField
							label="Duration in months"
							value={durationInMonths}
							onChange={setDurationInMonths}
						/>
					)}
				</Group>
				<Group>
					<Checkbox
						label="Limit redemption time"
						isChecked={isRedemptionDateLimited}
						onChange={setRedemptionDateLimited}
					/>
					<InputField
						inputType="datetime-local"
						label="Last redemption date"
						value={isRedemptionDateLimited ? redemptionDate : ""}
						onChange={setRedemptionDate}
						disabled={!isRedemptionDateLimited}
					/>
				</Group>
			</ModalInputs>
			<ModalButtons>
				<Button variant="secondary" isDisabled={isSubmitting} onClick={onClose}>
					Cancel
				</Button>
				<SubmitButton variant="primary" isDisabled={validatedData === null} isLoading={isSubmitting}>
					Create
				</SubmitButton>
			</ModalButtons>
		</DefaultModal>
	)
}

const DiscountContainer = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`

const DiscountValue = styled(InputField)`
	flex: 1 1 auto;
`

const DiscountUnit = styled(Dropdown)`
	flex: 0 0 100px;
	margin-top: 26px;
`

const Group = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

export default CreateCodeModal
