import { type FC } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import FeatureIntroduction from "~/components/FeatureIntroduction"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"

import illustration from "./illustration.svg"

type Props = { value: boolean; onChange(value: boolean): void }
const IssueCertificates: FC<Props> = ({ value, onChange }) => {
	const platform = usePlatform()!

	return (
		<Container>
			<FeatureIntroduction
				illustrationUrl={illustration}
				title="Issue certificates"
				text="Enable this option to automatically issue certificates to users upon successful completion of a course. This ensures that users receive formal recognition for their achievements, which they can use to showcase their skills and knowledge."
			>
				{platform.plan.access.certificates ? (
					<Checkbox label="Enable" isChecked={value} onChange={onChange} />
				) : (
					<>
						<Text>You need to upgrade your plan to access this feature.</Text>
						<Button variant="primary" onClick={routes.settings.billing.plans()}>
							Browse plans
						</Button>
					</>
				)}
			</FeatureIntroduction>
		</Container>
	)
}

const Container = styled.div`
	margin-top: 24px;
`

const Text = styled.p`
	color: ${lightTextColor};
	text-align: center;
	max-width: 500px;
	font-style: italic;
`

export default IssueCertificates
