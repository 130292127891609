import { useEffect, useState } from "react"

import { type CoursePageInputType, coursePageInputTypes } from "@forento/shared/models/course"

import Button from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import InputField, { useRichTextArea } from "~/components/InputField"

import Attachments from "./Attachments"
import Image from "./Image"
import Links from "./Links"
import { ButtonsGroup, ButtonsRow } from "./styles"

interface Props {
	page: {
		id: number
		title: string
		text: string | null
		image: {
			filePath: string
			contain: boolean
		} | null
		inputType: CoursePageInputType | null
		attachments: {
			id: number
			fileType: string
			label: string
			url: string
		}[]
		links: {
			id: number
			label: string
			url: string
		}[]
	}
	onClose: () => void
	onSavePage: (data: {
		title: string
		text: string | null
		containImage: boolean
		inputType: CoursePageInputType
	}) => void
	onDeletePage: () => void
}

const EditInputPageModalContent: React.FC<Props> = ({ page, onClose, onSavePage, onDeletePage }) => {
	const [title, setTitle] = useState(page.title)
	const text = useRichTextArea({ label: "Text" })
	const [containImage, setContainImage] = useState(page.image?.contain ?? false)
	const [inputType, setInputType] = useState<CoursePageInputType>(page.inputType ?? "single-line")

	const setText = text.set
	useEffect(() => {
		setTitle(page.title)
		setText(page.text)
		setContainImage(page.image?.contain ?? false)
		setInputType(page.inputType ?? "single-line")
	}, [page, setText])

	return (
		<>
			<InputField label="Title" value={title} onChange={setTitle} />
			{text.element}
			<Dropdown
				items={coursePageInputTypes.map(inputType => ({
					id: inputType,
					title: inputType === "single-line" ? "Single line" : "Multiple lines",
				}))}
				selectedItemId={inputType}
				onChange={value => setInputType(value as CoursePageInputType)}
			/>
			<Image
				pageId={page.id}
				filePath={page.image?.filePath ?? null}
				containImage={containImage}
				setContainImage={setContainImage}
			/>
			<Attachments pageId={page.id} attachments={page.attachments} />
			<Links pageId={page.id} links={page.links} />
			<ButtonsRow>
				<ButtonsGroup>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant="primary"
						isDisabled={title.trim().length === 0}
						onClick={() => onSavePage({ title, text: text.exportEditorState(), containImage, inputType })}
					>
						Save
					</Button>
				</ButtonsGroup>
				<Button variant="primary-danger" onClick={onDeletePage}>
					Delete
				</Button>
			</ButtonsRow>
		</>
	)
}

export default EditInputPageModalContent
