import { useState } from "react"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router"

import Form from "@forento/shared/components/Form"

import { SubmitButton } from "~/components/Button"
import InputField from "~/components/InputField"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc, { getInputErrors } from "~/utilities/trpc"

import Layout, {
	Content,
	Inputs,
	Logo,
	NavigationText,
	NavigationButton,
	Text,
	Title,
	GoogleSigninButton,
} from "./Layout"

const SigninPage: React.FC = () => {
	const alert = useAlert()
	const user = useUser()
	const navigate = useNavigate()

	const [isSubmitting, setSubmitting] = useState(false)
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")

	const nextUrl = useSearchParams()[0].get("next")

	const handleLogin = async () => {
		if (email.trim().length === 0 || password.length === 0) return

		try {
			setSubmitting(true)
			const result = await trpc.user.loginPassword.mutate({ email: email.trim(), password })

			if (result.status !== "success") {
				await alert.show("Login failed", "Invalid login credentials.")
				return
			}

			await user.reload()

			navigate(nextUrl ?? routes.main.index())
		} catch (error) {
			const errors = getInputErrors(error)
			if ("email" in errors) {
				await alert.show("Login failed", "Invalid email address.")
				return
			}
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout>
			<Logo />
			<Content as={Form} onSubmit={handleLogin}>
				<Title>Log in to your account</Title>
				<Text>Welcome back! Please enter your details.</Text>
				<Inputs>
					<InputField
						inputType="email"
						label="Email"
						value={email}
						onChange={setEmail}
						placeholder="Enter your email"
						autoComplete="email"
					/>
					<InputField
						inputType="password"
						label="Password"
						value={password}
						onChange={setPassword}
						placeholder="Enter your password"
						autoComplete="current-password"
					/>
				</Inputs>
				<NavigationButton onClick={routes.account.resetPassword()}>Forgot password</NavigationButton>
				<SubmitButton variant="primary" isLoading={isSubmitting}>
					Sign in
				</SubmitButton>

				<GoogleSigninButton text="signin" />

				<NavigationText>
					Don't have an account?{" "}
					<NavigationButton onClick={routes.account.signup()}>Sign up</NavigationButton>
				</NavigationText>
			</Content>
		</Layout>
	)
}

export default SigninPage
