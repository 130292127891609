import { type FC, useEffect } from "react"
import { Navigate, useNavigate, useSearchParams } from "react-router"

import { type AddonType, addonTypes, subscriptionPaymentIntervals } from "@forento/shared/models/payment"

import FullLoadingPage from "~/components/FullLoadingPage"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

const StartAddonSubscriptionPage: FC = () => {
	const platform = usePlatform()!
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const paymentInterval = subscriptionPaymentIntervals.find(x => x === searchParams.get("payment-interval")) ?? null
	const type: AddonType =
		addonTypes.find(x => x.toLowerCase() === searchParams.get("type")?.toLowerCase()) ?? "noCommission"

	const isValid =
		(platform.plan.type === "appsumo" || platform.plan.type === "viededingue") && paymentInterval !== null

	useEffect(() => {
		const abortController = new AbortController()

		async function run() {
			if (!isValid) return

			const { redirectUrl } = await trpc.payment.creator.addAddon.mutate(
				{ type, paymentInterval, source: "email" },
				{ signal: abortController.signal },
			)
			if (redirectUrl) {
				window.location.href = redirectUrl
			} else {
				navigate(routes.account.planStarted())
			}
		}

		run()

		return () => {
			abortController.abort()
		}
	}, [isValid, navigate, paymentInterval, type])

	if (!isValid) return <Navigate to={routes.main.index()} />

	return <FullLoadingPage />
}

export default StartAddonSubscriptionPage
