import party, { Rect } from "party-js"
import { type FC, useEffect } from "react"
import { useSearchParams } from "react-router"
import styled from "styled-components"

import Button from "~/components/Button"
import routes from "~/utilities/routes"
import { primaryLightColor } from "~/utilities/styles"

import illustration from "./illustration.svg"

const PlanStartedPage: FC = () => {
	const nextUrl = useSearchParams()[0].get("next") ?? routes.main.index()

	useEffect(() => {
		const interval = setInterval(() => {
			party.confetti(
				new Rect(window.innerWidth / 4 + (Math.random() * window.innerWidth) / 2, window.innerHeight, 1, 1),
				{
					count: party.variation.range(20, 40),
					spread: 40,
					size: 1,
					speed: party.variation.range(700, 1400),
				},
			)
		}, 750)

		return () => {
			clearInterval(interval)
		}
	}, [])

	return (
		<Container>
			<Illustration alt="Party illustration" src={illustration} />
			<Title>Great choice! 🥳</Title>
			<Text>You've chosen a great plan to improve your business!</Text>
			<Text>Don't forget, if you ever need help we're waiting for you in the chat!</Text>
			<Button variant="primary" onClick={nextUrl}>
				Continue
			</Button>
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: ${primaryLightColor};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const Illustration = styled.img`
	height: 200px;
	margin-bottom: 16px;
`

const Title = styled.h1`
	font-weight: 600;
	font-size: 26px;
	text-align: center;
	margin-bottom: 16px;
`

const Text = styled.p`
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	margin-bottom: 1em;
`

export default PlanStartedPage
