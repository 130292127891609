import { type LexicalEditor as LexicalEditorType } from "lexical"
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import type React from "react"
import { useId } from "react"
import styled, { css } from "styled-components"

import LexicalEditor from "@forento/shared/components/LexicalEditor"
import { exportEditorState, isEditorStateEmpty, loadEditorState } from "@forento/shared/utilities/lexical"

import { primaryLightColor } from "~/utilities/styles"

import InputLabel from "./InputLabel"

interface InputFieldProps {
	label?: string
	value: string
	onChange?: (value: string) => void
	onFocus?: () => void
	placeholder?: string
	inputType?: "text" | "number" | "email" | "password" | "datetime-local"
	autoComplete?: "given-name" | "family-name" | "email" | "current-password" | "new-password"
	disabled?: boolean
	compact?: boolean
	className?: string
}

interface InputTextAreaProps {
	label?: string
	value: string
	onChange: (value: string) => void
	compact?: boolean
	className?: string
}

const InputField: React.FC<InputFieldProps> = ({
	label,
	value,
	onChange,
	onFocus,
	placeholder,
	inputType = "text",
	autoComplete,
	disabled,
	compact,
	className,
}) => {
	const inputId = useId()

	return (
		<Container className={className}>
			{label !== undefined && <InputLabel htmlFor={inputId}>{label}</InputLabel>}
			<Input
				id={inputId}
				type={inputType}
				value={value}
				onChange={e => onChange?.(e.target.value)}
				onFocus={onFocus}
				placeholder={placeholder}
				autoComplete={autoComplete}
				disabled={disabled}
				$isCompact={compact ?? false}
				data-sentry-mask={inputType === "password" ? true : undefined}
			/>
		</Container>
	)
}

export const InputTextArea: React.FC<InputTextAreaProps> = ({ label, value, onChange, compact, className }) => {
	const inputId = useId()

	return (
		<Container className={className}>
			{label !== undefined && <InputLabel htmlFor={inputId}>{label}</InputLabel>}
			<TextArea
				id={inputId}
				value={value}
				onChange={e => onChange(e.target.value)}
				$isCompact={compact ?? false}
			/>
		</Container>
	)
}

type RichTextAreaParams = { label?: string; isCompact?: boolean; initialValue?: string | null }
export function useRichTextArea({ label, isCompact = false, initialValue }: RichTextAreaParams) {
	const id = useId()
	const [editorRef, setEditorRef] = useState<LexicalEditorType>()
	const [isModified, setModified] = useState(false)

	const element = useMemo(
		() => (
			<Container onClick={() => editorRef?.focus()}>
				{label !== undefined && <InputLabel htmlFor={id}>{label}</InputLabel>}
				<RichTextArea id={id} $isCompact={isCompact}>
					<LexicalEditor editorRef={setEditorRef} />
				</RichTextArea>
			</Container>
		),
		[editorRef, id, isCompact, label],
	)

	useLayoutEffect(() => {
		if (editorRef && initialValue !== undefined) {
			loadEditorState(editorRef, initialValue)
		}
	}, [initialValue, editorRef])

	useEffect(() => {
		if (!editorRef) return
		const removeListener = editorRef.registerUpdateListener(({ editorState }) => {
			if (initialValue) {
				setModified(JSON.stringify(editorState) !== initialValue)
			} else {
				setModified(!isEditorStateEmpty(editorState))
			}
		})
		return () => {
			removeListener()
		}
	}, [editorRef, initialValue, isModified])

	const handleSet = useCallback(
		(data: string | null) => {
			if (!editorRef) return
			loadEditorState(editorRef, data)
		},
		[editorRef],
	)

	const handleSetUnmodified = useCallback(() => {
		setModified(false)
	}, [])

	return useMemo(
		() => ({
			element,
			set: handleSet,
			exportEditorState() {
				if (!editorRef) return null
				return exportEditorState(editorRef.getEditorState())
			},
			setUnmodified: handleSetUnmodified,
			isModified,
		}),
		[editorRef, element, handleSet, handleSetUnmodified, isModified],
	)
}

const Container = styled.div`
	min-width: 0;
	display: flex;
	flex-direction: column;
`

const inputStyles = css<{ $isCompact: boolean }>`
	font-size: 16px;
	color: black;
	border: 1px solid #d0d5dd;
	border-radius: 8px;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	padding: ${props => (props.$isCompact ? "8px 12px" : "16px")};

	&::placeholder {
		color: #6d6d6d;
	}

	&:disabled {
		background-color: ${primaryLightColor};
	}
`

const Input = styled.input<{ $isCompact: boolean }>`
	${inputStyles}
	line-height: 1;
`

const TextArea = styled.textarea<{ $isCompact: boolean }>`
	${inputStyles}
	resize: vertical;
	min-height: 104px; // Twice the height of an input field
`

const RichTextArea = styled.div<{ $isCompact: boolean }>`
	${inputStyles}
	min-height: 104px; // Twice the height of an input field
	display: flex;
	flex-direction: column;
	cursor: text;
`

export default InputField
